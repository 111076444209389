@charset "UTF-8";
/**
* This file is for Bootstrap 4 styles specifically for components the react app
* All classes in this file, except those listed under ignored in postcss.config.js, will be prefixed with 'bs4-'
* With this implementation, only components will be prefixed with bs4- to minimize amount of unset-ing needed when fighting with collisions with Bootstrap 3
* Adding 'bs4-' to the classNames is specified in react-app.tsx
* The top portion of this file is used for unset-ing collisions with Bootstrap 3
*/
/* Unset Styles -  Start */
.react-app .fade, .multiple-react-app .fade, .tooltip .fade, .popover .fade, .modal .fade {
  opacity: 1;
  opacity: unset;
  transition: unset;
}

.tooltip.fade, .popover.fade, .modal.fade, .modal-backdrop.fade {
  opacity: 1;
  opacity: unset;
  transition: unset;
}

/* Unset Styles -  End */
/**
* This file is for setting custom Bootstrap 4 variables
* It should only override relevant variables from node_modules/bootstrap/scss/_variables.scss
* https://getbootstrap.com/docs/4.3/getting-started/theming/
*/
/*  Bootstrap spacing variables  */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #0E72ED;
  --secondary: #6c757d;
  --success: #00843C;
  --info: #eaf7f7;
  --warning: #fa6625;
  --danger: #eb0c3b;
  --light: #f8f9fa;
  --dark: #343a40;
  --logo: #467b8c;
  --black: #1d2126;
  --gray-1: #3a424d;
  --gray-2: #576373;
  --gray-3: #748499;
  --gray-4: #acb5c2;
  --gray-5: #d4dae1;
  --gray-6: #e4e7ec;
  --gray-7: #f7f8fa;
  --yellow: #fdce00;
  --kelp: #03a569;
  --high-tide: #0b4dc2;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: "Open Sans", Helvetica, Arial, sans-serif;
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1, .h1 {
  font-size: 3.5rem;
}

h2, .h2 {
  font-size: 2.8rem;
}

h3, .h3 {
  font-size: 2.45rem;
}

h4, .h4 {
  font-size: 2.1rem;
}

h5, .h5 {
  font-size: 1.75rem;
}

h6, .h6 {
  font-size: 1.4rem;
}

.lead {
  font-size: 1.75rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 0.875em;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.75rem;
}

.blockquote-footer {
  display: block;
  font-size: 0.875em;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "— ";
}

.container,
.container-fluid,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.col-xl,
.col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg,
.col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md,
.col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm,
.col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col,
.col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.33333333%;
  max-width: 8.33333333%;
}

.col-2 {
  flex: 0 0 16.66666667%;
  max-width: 16.66666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.33333333%;
  max-width: 33.33333333%;
}

.col-5 {
  flex: 0 0 41.66666667%;
  max-width: 41.66666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.33333333%;
  max-width: 58.33333333%;
}

.col-8 {
  flex: 0 0 66.66666667%;
  max-width: 66.66666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.33333333%;
  max-width: 83.33333333%;
}

.col-11 {
  flex: 0 0 91.66666667%;
  max-width: 91.66666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 13;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 13;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 13;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
}
.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
}
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}
.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #dee2e6;
}
.table-bordered th,
.table-bordered td {
  border: 1px solid #dee2e6;
}
.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #bcd8fa;
}
.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #82b6f6;
}

.table-hover .table-primary:hover {
  background-color: #a4caf8;
}
.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #a4caf8;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db;
}
.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #b3b7bb;
}

.table-hover .table-secondary:hover {
  background-color: #c8cbcf;
}
.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #c8cbcf;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #b8ddc8;
}
.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #7abf9a;
}

.table-hover .table-success:hover {
  background-color: #a7d5bb;
}
.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #a7d5bb;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #f9fdfd;
}
.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #f4fbfb;
}

.table-hover .table-info:hover {
  background-color: #e6f7f7;
}
.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #e6f7f7;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #fed4c2;
}
.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #fcaf8e;
}

.table-hover .table-warning:hover {
  background-color: #fec2a9;
}
.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #fec2a9;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f9bbc8;
}
.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #f58199;
}

.table-hover .table-danger:hover {
  background-color: #f7a4b5;
}
.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f7a4b5;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe;
}
.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc;
}

.table-hover .table-light:hover {
  background-color: #ececf6;
}
.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #ececf6;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca;
}
.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c;
}

.table-hover .table-dark:hover {
  background-color: #b9bbbe;
}
.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b9bbbe;
}

.table-logo,
.table-logo > th,
.table-logo > td {
  background-color: #cbdadf;
}
.table-logo th,
.table-logo td,
.table-logo thead th,
.table-logo tbody + tbody {
  border-color: #9fbac3;
}

.table-hover .table-logo:hover {
  background-color: #bbcfd5;
}
.table-hover .table-logo:hover > td,
.table-hover .table-logo:hover > th {
  background-color: #bbcfd5;
}

.table-black,
.table-black > th,
.table-black > td {
  background-color: #c0c1c2;
}
.table-black th,
.table-black td,
.table-black thead th,
.table-black tbody + tbody {
  border-color: #898c8e;
}

.table-hover .table-black:hover {
  background-color: #b3b4b5;
}
.table-hover .table-black:hover > td,
.table-hover .table-black:hover > th {
  background-color: #b3b4b5;
}

.table-gray-1,
.table-gray-1 > th,
.table-gray-1 > td {
  background-color: #c8cacd;
}
.table-gray-1 th,
.table-gray-1 td,
.table-gray-1 thead th,
.table-gray-1 tbody + tbody {
  border-color: #999da2;
}

.table-hover .table-gray-1:hover {
  background-color: #bbbdc1;
}
.table-hover .table-gray-1:hover > td,
.table-hover .table-gray-1:hover > th {
  background-color: #bbbdc1;
}

.table-gray-2,
.table-gray-2 > th,
.table-gray-2 > td {
  background-color: #d0d3d8;
}
.table-gray-2 th,
.table-gray-2 td,
.table-gray-2 thead th,
.table-gray-2 tbody + tbody {
  border-color: #a8aeb6;
}

.table-hover .table-gray-2:hover {
  background-color: #c2c6cc;
}
.table-hover .table-gray-2:hover > td,
.table-hover .table-gray-2:hover > th {
  background-color: #c2c6cc;
}

.table-gray-3,
.table-gray-3 > th,
.table-gray-3 > td {
  background-color: #d8dde2;
}
.table-gray-3 th,
.table-gray-3 td,
.table-gray-3 thead th,
.table-gray-3 tbody + tbody {
  border-color: #b7bfca;
}

.table-hover .table-gray-3:hover {
  background-color: #c9d0d7;
}
.table-hover .table-gray-3:hover > td,
.table-hover .table-gray-3:hover > th {
  background-color: #c9d0d7;
}

.table-gray-4,
.table-gray-4 > th,
.table-gray-4 > td {
  background-color: #e8eaee;
}
.table-gray-4 th,
.table-gray-4 td,
.table-gray-4 thead th,
.table-gray-4 tbody + tbody {
  border-color: #d4d9df;
}

.table-hover .table-gray-4:hover {
  background-color: #d9dde3;
}
.table-hover .table-gray-4:hover > td,
.table-hover .table-gray-4:hover > th {
  background-color: #d9dde3;
}

.table-gray-5,
.table-gray-5 > th,
.table-gray-5 > td {
  background-color: #f3f5f7;
}
.table-gray-5 th,
.table-gray-5 td,
.table-gray-5 thead th,
.table-gray-5 tbody + tbody {
  border-color: #e9ecef;
}

.table-hover .table-gray-5:hover {
  background-color: #e4e8ed;
}
.table-hover .table-gray-5:hover > td,
.table-hover .table-gray-5:hover > th {
  background-color: #e4e8ed;
}

.table-gray-6,
.table-gray-6 > th,
.table-gray-6 > td {
  background-color: #f7f8fa;
}
.table-gray-6 th,
.table-gray-6 td,
.table-gray-6 thead th,
.table-gray-6 tbody + tbody {
  border-color: #f1f3f5;
}

.table-hover .table-gray-6:hover {
  background-color: #e7eaf0;
}
.table-hover .table-gray-6:hover > td,
.table-hover .table-gray-6:hover > th {
  background-color: #e7eaf0;
}

.table-gray-7,
.table-gray-7 > th,
.table-gray-7 > td {
  background-color: #fdfdfe;
}
.table-gray-7 th,
.table-gray-7 td,
.table-gray-7 thead th,
.table-gray-7 tbody + tbody {
  border-color: #fbfbfc;
}

.table-hover .table-gray-7:hover {
  background-color: #ececf6;
}
.table-hover .table-gray-7:hover > td,
.table-hover .table-gray-7:hover > th {
  background-color: #ececf6;
}

.table-yellow,
.table-yellow > th,
.table-yellow > td {
  background-color: #fef1b8;
}
.table-yellow th,
.table-yellow td,
.table-yellow thead th,
.table-yellow tbody + tbody {
  border-color: #fee67a;
}

.table-hover .table-yellow:hover {
  background-color: #feec9f;
}
.table-hover .table-yellow:hover > td,
.table-hover .table-yellow:hover > th {
  background-color: #feec9f;
}

.table-kelp,
.table-kelp > th,
.table-kelp > td {
  background-color: #b8e6d5;
}
.table-kelp th,
.table-kelp td,
.table-kelp thead th,
.table-kelp tbody + tbody {
  border-color: #7cd0b1;
}

.table-hover .table-kelp:hover {
  background-color: #a5dfca;
}
.table-hover .table-kelp:hover > td,
.table-hover .table-kelp:hover > th {
  background-color: #a5dfca;
}

.table-high-tide,
.table-high-tide > th,
.table-high-tide > td {
  background-color: #bbcdee;
}
.table-high-tide th,
.table-high-tide td,
.table-high-tide thead th,
.table-high-tide tbody + tbody {
  border-color: #80a2df;
}

.table-hover .table-high-tide:hover {
  background-color: #a7bee9;
}
.table-hover .table-high-tide:hover > td,
.table-hover .table-high-tide:hover > th {
  background-color: #a7bee9;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}
.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55;
}
.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.table-dark {
  color: #fff;
  background-color: #343a40;
}
.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #454d55;
}
.table-dark.table-bordered {
  border: 0;
}
.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}
.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  height: 4rem;
  padding: 1rem 1rem;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.4285714286;
  color: #1d2126;
  background-color: #f7f8fa;
  background-clip: padding-box;
  border: 1px solid #acb5c2;
  border-radius: 0;
  box-shadow: 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.form-control:focus {
  color: #1d2126;
  background-color: #ffffff;
  border-color: #0E72ED;
  outline: 0;
  box-shadow: 0, 0 0 0 0.2rem rgba(14, 114, 237, 0.25);
}
.form-control::placeholder {
  color: #748499;
  opacity: 1;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

input[type=date].form-control,
input[type=time].form-control,
input[type=datetime-local].form-control,
input[type=month].form-control {
  appearance: none;
}

select.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #1d2126;
}
select.form-control:focus::-ms-value {
  color: #1d2126;
  background-color: #f7f8fa;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(1rem + 1px);
  padding-bottom: calc(1rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.4285714286;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.75rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.4rem + 1px);
  padding-bottom: calc(0.4rem + 1px);
  font-size: 1.4rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 1rem 0;
  margin-bottom: 0;
  font-size: 1.4rem;
  line-height: 1.4285714286;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.5em + 0.8rem + 2px);
  padding: 0.4rem 1.2rem;
  font-size: 1.4rem;
  line-height: 1.5;
  border-radius: 0;
}

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.75rem;
  line-height: 1.5;
  border-radius: 0;
}

select.form-control[size], select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.form-row > .col,
.form-row > [class*=col-] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  color: #6c757d;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}
.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #00843C;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 1.225rem;
  line-height: 1.4285714286;
  color: #fff;
  background-color: rgba(0, 132, 60, 0.9);
  border-radius: 2px;
}
.form-row > .col > .valid-tooltip, .form-row > [class*=col-] > .valid-tooltip {
  left: 5px;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #00843C;
  padding-right: calc(1.4285714286em + 2rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2300843C' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.3571428571em + 0.5rem) center;
  background-size: calc(0.7142857143em + 1rem) calc(0.7142857143em + 1rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #00843C;
  box-shadow: 0 0 0 0.2rem rgba(0, 132, 60, 0.25);
}

.was-validated select.form-control:valid, select.form-control.is-valid {
  padding-right: 4rem !important;
  background-position: right 2rem center;
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.4285714286em + 2rem);
  background-position: top calc(0.3571428571em + 0.5rem) right calc(0.3571428571em + 0.5rem);
}

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #00843C;
  padding-right: calc(0.75em + 3.5rem) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 1rem center/8px 10px no-repeat, #f7f8fa url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2300843C' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") center right 2rem/calc(0.7142857143em + 1rem) calc(0.7142857143em + 1rem) no-repeat;
}
.was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
  border-color: #00843C;
  box-shadow: 0 0 0 0.2rem rgba(0, 132, 60, 0.25);
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #00843C;
}
.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #00843C;
}
.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #00843C;
}
.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #00b753;
  background-color: #00b753;
}
.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(0, 132, 60, 0.25);
}
.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #00843C;
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #00843C;
}
.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #00843C;
  box-shadow: 0 0 0 0.2rem rgba(0, 132, 60, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #eb0c3b;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 1.225rem;
  line-height: 1.4285714286;
  color: #fff;
  background-color: rgba(235, 12, 59, 0.9);
  border-radius: 2px;
}
.form-row > .col > .invalid-tooltip, .form-row > [class*=col-] > .invalid-tooltip {
  left: 5px;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #eb0c3b;
  padding-right: calc(1.4285714286em + 2rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23eb0c3b' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23eb0c3b' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.3571428571em + 0.5rem) center;
  background-size: calc(0.7142857143em + 1rem) calc(0.7142857143em + 1rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #eb0c3b;
  box-shadow: 0 0 0 0.2rem rgba(235, 12, 59, 0.25);
}

.was-validated select.form-control:invalid, select.form-control.is-invalid {
  padding-right: 4rem !important;
  background-position: right 2rem center;
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.4285714286em + 2rem);
  background-position: top calc(0.3571428571em + 0.5rem) right calc(0.3571428571em + 0.5rem);
}

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #eb0c3b;
  padding-right: calc(0.75em + 3.5rem) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 1rem center/8px 10px no-repeat, #f7f8fa url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23eb0c3b' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23eb0c3b' stroke='none'/%3e%3c/svg%3e") center right 2rem/calc(0.7142857143em + 1rem) calc(0.7142857143em + 1rem) no-repeat;
}
.was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
  border-color: #eb0c3b;
  box-shadow: 0 0 0 0.2rem rgba(235, 12, 59, 0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #eb0c3b;
}
.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #eb0c3b;
}
.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #eb0c3b;
}
.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #f5355e;
  background-color: #f5355e;
}
.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(235, 12, 59, 0.25);
}
.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #eb0c3b;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #eb0c3b;
}
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #eb0c3b;
  box-shadow: 0 0 0 0.2rem rgba(235, 12, 59, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.form-inline .form-check {
  width: 100%;
}
@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
  .form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.7rem 1.2rem;
  font-size: 1.4rem;
  line-height: 1.4285714286;
  border-radius: 4px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: #212529;
  text-decoration: none;
}
.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(14, 114, 237, 0.25);
}
.btn.disabled, .btn:disabled {
  opacity: 0.65;
  box-shadow: none;
}
.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}
.btn:not(:disabled):not(.disabled):active, .btn:not(:disabled):not(.disabled).active {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.btn:not(:disabled):not(.disabled):active:focus, .btn:not(:disabled):not(.disabled).active:focus {
  box-shadow: 0 0 0 0.2rem rgba(14, 114, 237, 0.25), inset 0 3px 5px rgba(0, 0, 0, 0.125);
}

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #0E72ED;
  border-color: #0E72ED;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-primary:hover {
  color: #fff;
  background-color: #0c61c9;
  border-color: #0b5bbd;
}
.btn-primary:focus, .btn-primary.focus {
  color: #fff;
  background-color: #0c61c9;
  border-color: #0b5bbd;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(50, 135, 240, 0.5);
}
.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #0E72ED;
  border-color: #0E72ED;
}
.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0b5bbd;
  border-color: #0a55b1;
}
.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(50, 135, 240, 0.5);
}

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-secondary:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
}
.btn-secondary:focus, .btn-secondary.focus {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}
.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #545b62;
  border-color: #4e555b;
}
.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}

.btn-success {
  color: #fff;
  background-color: #00843C;
  border-color: #00843C;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-success:hover {
  color: #fff;
  background-color: #005e2b;
  border-color: #005125;
}
.btn-success:focus, .btn-success.focus {
  color: #fff;
  background-color: #005e2b;
  border-color: #005125;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(38, 150, 89, 0.5);
}
.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #00843C;
  border-color: #00843C;
}
.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active, .show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #005125;
  border-color: #00441f;
}
.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(38, 150, 89, 0.5);
}

.btn-info {
  color: #212529;
  background-color: #eaf7f7;
  border-color: #eaf7f7;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-info:hover {
  color: #212529;
  background-color: #ceecec;
  border-color: #c5e9e9;
}
.btn-info:focus, .btn-info.focus {
  color: #212529;
  background-color: #ceecec;
  border-color: #c5e9e9;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(204, 216, 216, 0.5);
}
.btn-info.disabled, .btn-info:disabled {
  color: #212529;
  background-color: #eaf7f7;
  border-color: #eaf7f7;
}
.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active, .show > .btn-info.dropdown-toggle {
  color: #212529;
  background-color: #c5e9e9;
  border-color: #bce5e5;
}
.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus, .show > .btn-info.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(204, 216, 216, 0.5);
}

.btn-warning {
  color: #fff;
  background-color: #fa6625;
  border-color: #fa6625;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-warning:hover {
  color: #fff;
  background-color: #f34e06;
  border-color: #e74a05;
}
.btn-warning:focus, .btn-warning.focus {
  color: #fff;
  background-color: #f34e06;
  border-color: #e74a05;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(251, 125, 70, 0.5);
}
.btn-warning.disabled, .btn-warning:disabled {
  color: #fff;
  background-color: #fa6625;
  border-color: #fa6625;
}
.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active, .show > .btn-warning.dropdown-toggle {
  color: #fff;
  background-color: #e74a05;
  border-color: #da4605;
}
.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(251, 125, 70, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #eb0c3b;
  border-color: #eb0c3b;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-danger:hover {
  color: #fff;
  background-color: #c70a32;
  border-color: #ba0a2f;
}
.btn-danger:focus, .btn-danger.focus {
  color: #fff;
  background-color: #c70a32;
  border-color: #ba0a2f;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(238, 48, 88, 0.5);
}
.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #eb0c3b;
  border-color: #eb0c3b;
}
.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active, .show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #ba0a2f;
  border-color: #ae092c;
}
.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(238, 48, 88, 0.5);
}

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}
.btn-light:focus, .btn-light.focus {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}
.btn-light.disabled, .btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active, .show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}
.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus, .show > .btn-light.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}
.btn-dark:focus, .btn-dark.focus {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}
.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active, .show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}
.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-dark.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}

.btn-logo {
  color: #fff;
  background-color: #467b8c;
  border-color: #467b8c;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-logo:hover {
  color: #fff;
  background-color: #396573;
  border-color: #355d6a;
}
.btn-logo:focus, .btn-logo.focus {
  color: #fff;
  background-color: #396573;
  border-color: #355d6a;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(98, 143, 157, 0.5);
}
.btn-logo.disabled, .btn-logo:disabled {
  color: #fff;
  background-color: #467b8c;
  border-color: #467b8c;
}
.btn-logo:not(:disabled):not(.disabled):active, .btn-logo:not(:disabled):not(.disabled).active, .show > .btn-logo.dropdown-toggle {
  color: #fff;
  background-color: #355d6a;
  border-color: #315662;
}
.btn-logo:not(:disabled):not(.disabled):active:focus, .btn-logo:not(:disabled):not(.disabled).active:focus, .show > .btn-logo.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(98, 143, 157, 0.5);
}

.btn-black {
  color: #fff;
  background-color: #1d2126;
  border-color: #1d2126;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-black:hover {
  color: #fff;
  background-color: #0c0e10;
  border-color: #070809;
}
.btn-black:focus, .btn-black.focus {
  color: #fff;
  background-color: #0c0e10;
  border-color: #070809;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(63, 66, 71, 0.5);
}
.btn-black.disabled, .btn-black:disabled {
  color: #fff;
  background-color: #1d2126;
  border-color: #1d2126;
}
.btn-black:not(:disabled):not(.disabled):active, .btn-black:not(:disabled):not(.disabled).active, .show > .btn-black.dropdown-toggle {
  color: #fff;
  background-color: #070809;
  border-color: #010202;
}
.btn-black:not(:disabled):not(.disabled):active:focus, .btn-black:not(:disabled):not(.disabled).active:focus, .show > .btn-black.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(63, 66, 71, 0.5);
}

.btn-gray-1 {
  color: #fff;
  background-color: #3a424d;
  border-color: #3a424d;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-gray-1:hover {
  color: #fff;
  background-color: #2a2f37;
  border-color: #242930;
}
.btn-gray-1:focus, .btn-gray-1.focus {
  color: #fff;
  background-color: #2a2f37;
  border-color: #242930;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(88, 94, 104, 0.5);
}
.btn-gray-1.disabled, .btn-gray-1:disabled {
  color: #fff;
  background-color: #3a424d;
  border-color: #3a424d;
}
.btn-gray-1:not(:disabled):not(.disabled):active, .btn-gray-1:not(:disabled):not(.disabled).active, .show > .btn-gray-1.dropdown-toggle {
  color: #fff;
  background-color: #242930;
  border-color: #1f2329;
}
.btn-gray-1:not(:disabled):not(.disabled):active:focus, .btn-gray-1:not(:disabled):not(.disabled).active:focus, .show > .btn-gray-1.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(88, 94, 104, 0.5);
}

.btn-gray-2 {
  color: #fff;
  background-color: #576373;
  border-color: #576373;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-gray-2:hover {
  color: #fff;
  background-color: #47505d;
  border-color: #414a56;
}
.btn-gray-2:focus, .btn-gray-2.focus {
  color: #fff;
  background-color: #47505d;
  border-color: #414a56;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(112, 122, 136, 0.5);
}
.btn-gray-2.disabled, .btn-gray-2:disabled {
  color: #fff;
  background-color: #576373;
  border-color: #576373;
}
.btn-gray-2:not(:disabled):not(.disabled):active, .btn-gray-2:not(:disabled):not(.disabled).active, .show > .btn-gray-2.dropdown-toggle {
  color: #fff;
  background-color: #414a56;
  border-color: #3c444f;
}
.btn-gray-2:not(:disabled):not(.disabled):active:focus, .btn-gray-2:not(:disabled):not(.disabled).active:focus, .show > .btn-gray-2.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(112, 122, 136, 0.5);
}

.btn-gray-3 {
  color: #fff;
  background-color: #748499;
  border-color: #748499;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-gray-3:hover {
  color: #fff;
  background-color: #627185;
  border-color: #5c6b7e;
}
.btn-gray-3:focus, .btn-gray-3.focus {
  color: #fff;
  background-color: #627185;
  border-color: #5c6b7e;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(137, 150, 168, 0.5);
}
.btn-gray-3.disabled, .btn-gray-3:disabled {
  color: #fff;
  background-color: #748499;
  border-color: #748499;
}
.btn-gray-3:not(:disabled):not(.disabled):active, .btn-gray-3:not(:disabled):not(.disabled).active, .show > .btn-gray-3.dropdown-toggle {
  color: #fff;
  background-color: #5c6b7e;
  border-color: #576476;
}
.btn-gray-3:not(:disabled):not(.disabled):active:focus, .btn-gray-3:not(:disabled):not(.disabled).active:focus, .show > .btn-gray-3.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(137, 150, 168, 0.5);
}

.btn-gray-4 {
  color: #212529;
  background-color: #acb5c2;
  border-color: #acb5c2;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-gray-4:hover {
  color: #212529;
  background-color: #96a1b2;
  border-color: #8f9bac;
}
.btn-gray-4:focus, .btn-gray-4.focus {
  color: #212529;
  background-color: #96a1b2;
  border-color: #8f9bac;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(151, 159, 171, 0.5);
}
.btn-gray-4.disabled, .btn-gray-4:disabled {
  color: #212529;
  background-color: #acb5c2;
  border-color: #acb5c2;
}
.btn-gray-4:not(:disabled):not(.disabled):active, .btn-gray-4:not(:disabled):not(.disabled).active, .show > .btn-gray-4.dropdown-toggle {
  color: #212529;
  background-color: #8f9bac;
  border-color: #8794a7;
}
.btn-gray-4:not(:disabled):not(.disabled):active:focus, .btn-gray-4:not(:disabled):not(.disabled).active:focus, .show > .btn-gray-4.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(151, 159, 171, 0.5);
}

.btn-gray-5 {
  color: #212529;
  background-color: #d4dae1;
  border-color: #d4dae1;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-gray-5:hover {
  color: #212529;
  background-color: #bdc7d1;
  border-color: #b6c0cc;
}
.btn-gray-5:focus, .btn-gray-5.focus {
  color: #212529;
  background-color: #bdc7d1;
  border-color: #b6c0cc;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(185, 191, 197, 0.5);
}
.btn-gray-5.disabled, .btn-gray-5:disabled {
  color: #212529;
  background-color: #d4dae1;
  border-color: #d4dae1;
}
.btn-gray-5:not(:disabled):not(.disabled):active, .btn-gray-5:not(:disabled):not(.disabled).active, .show > .btn-gray-5.dropdown-toggle {
  color: #212529;
  background-color: #b6c0cc;
  border-color: #aebac7;
}
.btn-gray-5:not(:disabled):not(.disabled):active:focus, .btn-gray-5:not(:disabled):not(.disabled).active:focus, .show > .btn-gray-5.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(185, 191, 197, 0.5);
}

.btn-gray-6 {
  color: #212529;
  background-color: #e4e7ec;
  border-color: #e4e7ec;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-gray-6:hover {
  color: #212529;
  background-color: #ced3dc;
  border-color: #c6ccd7;
}
.btn-gray-6:focus, .btn-gray-6.focus {
  color: #212529;
  background-color: #ced3dc;
  border-color: #c6ccd7;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(199, 202, 207, 0.5);
}
.btn-gray-6.disabled, .btn-gray-6:disabled {
  color: #212529;
  background-color: #e4e7ec;
  border-color: #e4e7ec;
}
.btn-gray-6:not(:disabled):not(.disabled):active, .btn-gray-6:not(:disabled):not(.disabled).active, .show > .btn-gray-6.dropdown-toggle {
  color: #212529;
  background-color: #c6ccd7;
  border-color: #bfc6d2;
}
.btn-gray-6:not(:disabled):not(.disabled):active:focus, .btn-gray-6:not(:disabled):not(.disabled).active:focus, .show > .btn-gray-6.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(199, 202, 207, 0.5);
}

.btn-gray-7 {
  color: #212529;
  background-color: #f7f8fa;
  border-color: #f7f8fa;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-gray-7:hover {
  color: #212529;
  background-color: #dfe3eb;
  border-color: #d8dde6;
}
.btn-gray-7:focus, .btn-gray-7.focus {
  color: #212529;
  background-color: #dfe3eb;
  border-color: #d8dde6;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(215, 216, 219, 0.5);
}
.btn-gray-7.disabled, .btn-gray-7:disabled {
  color: #212529;
  background-color: #f7f8fa;
  border-color: #f7f8fa;
}
.btn-gray-7:not(:disabled):not(.disabled):active, .btn-gray-7:not(:disabled):not(.disabled).active, .show > .btn-gray-7.dropdown-toggle {
  color: #212529;
  background-color: #d8dde6;
  border-color: #d0d6e1;
}
.btn-gray-7:not(:disabled):not(.disabled):active:focus, .btn-gray-7:not(:disabled):not(.disabled).active:focus, .show > .btn-gray-7.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(215, 216, 219, 0.5);
}

.btn-yellow {
  color: #212529;
  background-color: #fdce00;
  border-color: #fdce00;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-yellow:hover {
  color: #212529;
  background-color: #d7af00;
  border-color: #caa400;
}
.btn-yellow:focus, .btn-yellow.focus {
  color: #212529;
  background-color: #d7af00;
  border-color: #caa400;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(220, 181, 6, 0.5);
}
.btn-yellow.disabled, .btn-yellow:disabled {
  color: #212529;
  background-color: #fdce00;
  border-color: #fdce00;
}
.btn-yellow:not(:disabled):not(.disabled):active, .btn-yellow:not(:disabled):not(.disabled).active, .show > .btn-yellow.dropdown-toggle {
  color: #212529;
  background-color: #caa400;
  border-color: #bd9a00;
}
.btn-yellow:not(:disabled):not(.disabled):active:focus, .btn-yellow:not(:disabled):not(.disabled).active:focus, .show > .btn-yellow.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(220, 181, 6, 0.5);
}

.btn-kelp {
  color: #fff;
  background-color: #03a569;
  border-color: #03a569;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-kelp:hover {
  color: #fff;
  background-color: #027f51;
  border-color: #027349;
}
.btn-kelp:focus, .btn-kelp.focus {
  color: #fff;
  background-color: #027f51;
  border-color: #027349;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(41, 179, 128, 0.5);
}
.btn-kelp.disabled, .btn-kelp:disabled {
  color: #fff;
  background-color: #03a569;
  border-color: #03a569;
}
.btn-kelp:not(:disabled):not(.disabled):active, .btn-kelp:not(:disabled):not(.disabled).active, .show > .btn-kelp.dropdown-toggle {
  color: #fff;
  background-color: #027349;
  border-color: #026641;
}
.btn-kelp:not(:disabled):not(.disabled):active:focus, .btn-kelp:not(:disabled):not(.disabled).active:focus, .show > .btn-kelp.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(41, 179, 128, 0.5);
}

.btn-high-tide {
  color: #fff;
  background-color: #0b4dc2;
  border-color: #0b4dc2;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-high-tide:hover {
  color: #fff;
  background-color: #093f9e;
  border-color: #083a92;
}
.btn-high-tide:focus, .btn-high-tide.focus {
  color: #fff;
  background-color: #093f9e;
  border-color: #083a92;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(48, 104, 203, 0.5);
}
.btn-high-tide.disabled, .btn-high-tide:disabled {
  color: #fff;
  background-color: #0b4dc2;
  border-color: #0b4dc2;
}
.btn-high-tide:not(:disabled):not(.disabled):active, .btn-high-tide:not(:disabled):not(.disabled).active, .show > .btn-high-tide.dropdown-toggle {
  color: #fff;
  background-color: #083a92;
  border-color: #083586;
}
.btn-high-tide:not(:disabled):not(.disabled):active:focus, .btn-high-tide:not(:disabled):not(.disabled).active:focus, .show > .btn-high-tide.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(48, 104, 203, 0.5);
}

.btn-outline-primary {
  color: #0E72ED;
  border-color: #0E72ED;
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: #0E72ED;
  border-color: #0E72ED;
}
.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(14, 114, 237, 0.5);
}
.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #0E72ED;
  background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #0E72ED;
  border-color: #0E72ED;
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(14, 114, 237, 0.5);
}

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}
.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: transparent;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active, .show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-outline-success {
  color: #00843C;
  border-color: #00843C;
}
.btn-outline-success:hover {
  color: #fff;
  background-color: #00843C;
  border-color: #00843C;
}
.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 132, 60, 0.5);
}
.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #00843C;
  background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active, .show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #00843C;
  border-color: #00843C;
}
.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(0, 132, 60, 0.5);
}

.btn-outline-info {
  color: #eaf7f7;
  border-color: #eaf7f7;
}
.btn-outline-info:hover {
  color: #212529;
  background-color: #eaf7f7;
  border-color: #eaf7f7;
}
.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(234, 247, 247, 0.5);
}
.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #eaf7f7;
  background-color: transparent;
}
.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active, .show > .btn-outline-info.dropdown-toggle {
  color: #212529;
  background-color: #eaf7f7;
  border-color: #eaf7f7;
}
.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(234, 247, 247, 0.5);
}

.btn-outline-warning {
  color: #fa6625;
  border-color: #fa6625;
}
.btn-outline-warning:hover {
  color: #fff;
  background-color: #fa6625;
  border-color: #fa6625;
}
.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(250, 102, 37, 0.5);
}
.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #fa6625;
  background-color: transparent;
}
.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active, .show > .btn-outline-warning.dropdown-toggle {
  color: #fff;
  background-color: #fa6625;
  border-color: #fa6625;
}
.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(250, 102, 37, 0.5);
}

.btn-outline-danger {
  color: #eb0c3b;
  border-color: #eb0c3b;
}
.btn-outline-danger:hover {
  color: #fff;
  background-color: #eb0c3b;
  border-color: #eb0c3b;
}
.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(235, 12, 59, 0.5);
}
.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #eb0c3b;
  background-color: transparent;
}
.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active, .show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #eb0c3b;
  border-color: #eb0c3b;
}
.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(235, 12, 59, 0.5);
}

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}
.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active, .show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}
.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active, .show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-outline-logo {
  color: #467b8c;
  border-color: #467b8c;
}
.btn-outline-logo:hover {
  color: #fff;
  background-color: #467b8c;
  border-color: #467b8c;
}
.btn-outline-logo:focus, .btn-outline-logo.focus {
  box-shadow: 0 0 0 0.2rem rgba(70, 123, 140, 0.5);
}
.btn-outline-logo.disabled, .btn-outline-logo:disabled {
  color: #467b8c;
  background-color: transparent;
}
.btn-outline-logo:not(:disabled):not(.disabled):active, .btn-outline-logo:not(:disabled):not(.disabled).active, .show > .btn-outline-logo.dropdown-toggle {
  color: #fff;
  background-color: #467b8c;
  border-color: #467b8c;
}
.btn-outline-logo:not(:disabled):not(.disabled):active:focus, .btn-outline-logo:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-logo.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(70, 123, 140, 0.5);
}

.btn-outline-black {
  color: #1d2126;
  border-color: #1d2126;
}
.btn-outline-black:hover {
  color: #fff;
  background-color: #1d2126;
  border-color: #1d2126;
}
.btn-outline-black:focus, .btn-outline-black.focus {
  box-shadow: 0 0 0 0.2rem rgba(29, 33, 38, 0.5);
}
.btn-outline-black.disabled, .btn-outline-black:disabled {
  color: #1d2126;
  background-color: transparent;
}
.btn-outline-black:not(:disabled):not(.disabled):active, .btn-outline-black:not(:disabled):not(.disabled).active, .show > .btn-outline-black.dropdown-toggle {
  color: #fff;
  background-color: #1d2126;
  border-color: #1d2126;
}
.btn-outline-black:not(:disabled):not(.disabled):active:focus, .btn-outline-black:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-black.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(29, 33, 38, 0.5);
}

.btn-outline-gray-1 {
  color: #3a424d;
  border-color: #3a424d;
}
.btn-outline-gray-1:hover {
  color: #fff;
  background-color: #3a424d;
  border-color: #3a424d;
}
.btn-outline-gray-1:focus, .btn-outline-gray-1.focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 66, 77, 0.5);
}
.btn-outline-gray-1.disabled, .btn-outline-gray-1:disabled {
  color: #3a424d;
  background-color: transparent;
}
.btn-outline-gray-1:not(:disabled):not(.disabled):active, .btn-outline-gray-1:not(:disabled):not(.disabled).active, .show > .btn-outline-gray-1.dropdown-toggle {
  color: #fff;
  background-color: #3a424d;
  border-color: #3a424d;
}
.btn-outline-gray-1:not(:disabled):not(.disabled):active:focus, .btn-outline-gray-1:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-gray-1.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(58, 66, 77, 0.5);
}

.btn-outline-gray-2 {
  color: #576373;
  border-color: #576373;
}
.btn-outline-gray-2:hover {
  color: #fff;
  background-color: #576373;
  border-color: #576373;
}
.btn-outline-gray-2:focus, .btn-outline-gray-2.focus {
  box-shadow: 0 0 0 0.2rem rgba(87, 99, 115, 0.5);
}
.btn-outline-gray-2.disabled, .btn-outline-gray-2:disabled {
  color: #576373;
  background-color: transparent;
}
.btn-outline-gray-2:not(:disabled):not(.disabled):active, .btn-outline-gray-2:not(:disabled):not(.disabled).active, .show > .btn-outline-gray-2.dropdown-toggle {
  color: #fff;
  background-color: #576373;
  border-color: #576373;
}
.btn-outline-gray-2:not(:disabled):not(.disabled):active:focus, .btn-outline-gray-2:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-gray-2.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(87, 99, 115, 0.5);
}

.btn-outline-gray-3 {
  color: #748499;
  border-color: #748499;
}
.btn-outline-gray-3:hover {
  color: #fff;
  background-color: #748499;
  border-color: #748499;
}
.btn-outline-gray-3:focus, .btn-outline-gray-3.focus {
  box-shadow: 0 0 0 0.2rem rgba(116, 132, 153, 0.5);
}
.btn-outline-gray-3.disabled, .btn-outline-gray-3:disabled {
  color: #748499;
  background-color: transparent;
}
.btn-outline-gray-3:not(:disabled):not(.disabled):active, .btn-outline-gray-3:not(:disabled):not(.disabled).active, .show > .btn-outline-gray-3.dropdown-toggle {
  color: #fff;
  background-color: #748499;
  border-color: #748499;
}
.btn-outline-gray-3:not(:disabled):not(.disabled):active:focus, .btn-outline-gray-3:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-gray-3.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(116, 132, 153, 0.5);
}

.btn-outline-gray-4 {
  color: #acb5c2;
  border-color: #acb5c2;
}
.btn-outline-gray-4:hover {
  color: #212529;
  background-color: #acb5c2;
  border-color: #acb5c2;
}
.btn-outline-gray-4:focus, .btn-outline-gray-4.focus {
  box-shadow: 0 0 0 0.2rem rgba(172, 181, 194, 0.5);
}
.btn-outline-gray-4.disabled, .btn-outline-gray-4:disabled {
  color: #acb5c2;
  background-color: transparent;
}
.btn-outline-gray-4:not(:disabled):not(.disabled):active, .btn-outline-gray-4:not(:disabled):not(.disabled).active, .show > .btn-outline-gray-4.dropdown-toggle {
  color: #212529;
  background-color: #acb5c2;
  border-color: #acb5c2;
}
.btn-outline-gray-4:not(:disabled):not(.disabled):active:focus, .btn-outline-gray-4:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-gray-4.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(172, 181, 194, 0.5);
}

.btn-outline-gray-5 {
  color: #d4dae1;
  border-color: #d4dae1;
}
.btn-outline-gray-5:hover {
  color: #212529;
  background-color: #d4dae1;
  border-color: #d4dae1;
}
.btn-outline-gray-5:focus, .btn-outline-gray-5.focus {
  box-shadow: 0 0 0 0.2rem rgba(212, 218, 225, 0.5);
}
.btn-outline-gray-5.disabled, .btn-outline-gray-5:disabled {
  color: #d4dae1;
  background-color: transparent;
}
.btn-outline-gray-5:not(:disabled):not(.disabled):active, .btn-outline-gray-5:not(:disabled):not(.disabled).active, .show > .btn-outline-gray-5.dropdown-toggle {
  color: #212529;
  background-color: #d4dae1;
  border-color: #d4dae1;
}
.btn-outline-gray-5:not(:disabled):not(.disabled):active:focus, .btn-outline-gray-5:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-gray-5.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(212, 218, 225, 0.5);
}

.btn-outline-gray-6 {
  color: #e4e7ec;
  border-color: #e4e7ec;
}
.btn-outline-gray-6:hover {
  color: #212529;
  background-color: #e4e7ec;
  border-color: #e4e7ec;
}
.btn-outline-gray-6:focus, .btn-outline-gray-6.focus {
  box-shadow: 0 0 0 0.2rem rgba(228, 231, 236, 0.5);
}
.btn-outline-gray-6.disabled, .btn-outline-gray-6:disabled {
  color: #e4e7ec;
  background-color: transparent;
}
.btn-outline-gray-6:not(:disabled):not(.disabled):active, .btn-outline-gray-6:not(:disabled):not(.disabled).active, .show > .btn-outline-gray-6.dropdown-toggle {
  color: #212529;
  background-color: #e4e7ec;
  border-color: #e4e7ec;
}
.btn-outline-gray-6:not(:disabled):not(.disabled):active:focus, .btn-outline-gray-6:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-gray-6.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(228, 231, 236, 0.5);
}

.btn-outline-gray-7 {
  color: #f7f8fa;
  border-color: #f7f8fa;
}
.btn-outline-gray-7:hover {
  color: #212529;
  background-color: #f7f8fa;
  border-color: #f7f8fa;
}
.btn-outline-gray-7:focus, .btn-outline-gray-7.focus {
  box-shadow: 0 0 0 0.2rem rgba(247, 248, 250, 0.5);
}
.btn-outline-gray-7.disabled, .btn-outline-gray-7:disabled {
  color: #f7f8fa;
  background-color: transparent;
}
.btn-outline-gray-7:not(:disabled):not(.disabled):active, .btn-outline-gray-7:not(:disabled):not(.disabled).active, .show > .btn-outline-gray-7.dropdown-toggle {
  color: #212529;
  background-color: #f7f8fa;
  border-color: #f7f8fa;
}
.btn-outline-gray-7:not(:disabled):not(.disabled):active:focus, .btn-outline-gray-7:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-gray-7.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(247, 248, 250, 0.5);
}

.btn-outline-yellow {
  color: #fdce00;
  border-color: #fdce00;
}
.btn-outline-yellow:hover {
  color: #212529;
  background-color: #fdce00;
  border-color: #fdce00;
}
.btn-outline-yellow:focus, .btn-outline-yellow.focus {
  box-shadow: 0 0 0 0.2rem rgba(253, 206, 0, 0.5);
}
.btn-outline-yellow.disabled, .btn-outline-yellow:disabled {
  color: #fdce00;
  background-color: transparent;
}
.btn-outline-yellow:not(:disabled):not(.disabled):active, .btn-outline-yellow:not(:disabled):not(.disabled).active, .show > .btn-outline-yellow.dropdown-toggle {
  color: #212529;
  background-color: #fdce00;
  border-color: #fdce00;
}
.btn-outline-yellow:not(:disabled):not(.disabled):active:focus, .btn-outline-yellow:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-yellow.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(253, 206, 0, 0.5);
}

.btn-outline-kelp {
  color: #03a569;
  border-color: #03a569;
}
.btn-outline-kelp:hover {
  color: #fff;
  background-color: #03a569;
  border-color: #03a569;
}
.btn-outline-kelp:focus, .btn-outline-kelp.focus {
  box-shadow: 0 0 0 0.2rem rgba(3, 165, 105, 0.5);
}
.btn-outline-kelp.disabled, .btn-outline-kelp:disabled {
  color: #03a569;
  background-color: transparent;
}
.btn-outline-kelp:not(:disabled):not(.disabled):active, .btn-outline-kelp:not(:disabled):not(.disabled).active, .show > .btn-outline-kelp.dropdown-toggle {
  color: #fff;
  background-color: #03a569;
  border-color: #03a569;
}
.btn-outline-kelp:not(:disabled):not(.disabled):active:focus, .btn-outline-kelp:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-kelp.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(3, 165, 105, 0.5);
}

.btn-outline-high-tide {
  color: #0b4dc2;
  border-color: #0b4dc2;
}
.btn-outline-high-tide:hover {
  color: #fff;
  background-color: #0b4dc2;
  border-color: #0b4dc2;
}
.btn-outline-high-tide:focus, .btn-outline-high-tide.focus {
  box-shadow: 0 0 0 0.2rem rgba(11, 77, 194, 0.5);
}
.btn-outline-high-tide.disabled, .btn-outline-high-tide:disabled {
  color: #0b4dc2;
  background-color: transparent;
}
.btn-outline-high-tide:not(:disabled):not(.disabled):active, .btn-outline-high-tide:not(:disabled):not(.disabled).active, .show > .btn-outline-high-tide.dropdown-toggle {
  color: #fff;
  background-color: #0b4dc2;
  border-color: #0b4dc2;
}
.btn-outline-high-tide:not(:disabled):not(.disabled):active:focus, .btn-outline-high-tide:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-high-tide.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(11, 77, 194, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #0E72ED;
  text-decoration: none;
}
.btn-link:hover {
  color: #0a4fa5;
  text-decoration: underline;
}
.btn-link:focus, .btn-link.focus {
  text-decoration: underline;
}
.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
  pointer-events: none;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.75rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.4rem 1.2rem;
  font-size: 1.4rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.btn-block {
  display: block;
  width: 100%;
}
.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type=submit].btn-block,
input[type=reset].btn-block,
input[type=button].btn-block {
  width: 100%;
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0 0;
  margin: 0.125rem 0 0;
  font-size: 1.4rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  box-shadow: 0 5px 7px 0 rgba(29, 33, 38, 0.1);
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}
.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}
.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropleft .dropdown-toggle::after {
  display: none;
}
.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^=top], .dropdown-menu[x-placement^=right], .dropdown-menu[x-placement^=bottom], .dropdown-menu[x-placement^=left] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 7px 20px;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:first-child {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}
.dropdown-item:last-child {
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #eaf7f7;
}
.dropdown-item.active, .dropdown-item:active {
  color: #1d2126;
  text-decoration: none;
  background-color: transparent;
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: #adb5bd;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0 20px;
  margin-bottom: 0;
  font-size: 1.225rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 7px 20px;
  color: #212529;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}
.btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.9rem;
  padding-left: 0.9rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropright .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.9rem;
  padding-left: 0.9rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group.show .dropdown-toggle {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.btn-group.show .dropdown-toggle.btn-link {
  box-shadow: none;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}
.btn-group-toggle > .btn input[type=radio],
.btn-group-toggle > .btn input[type=checkbox],
.btn-group-toggle > .btn-group > .btn input[type=radio],
.btn-group-toggle > .btn-group > .btn input[type=checkbox] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}
.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .form-control-plaintext + .form-control,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -1px;
}
.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}
.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}
.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .custom-file {
  display: flex;
  align-items: center;
}
.input-group > .custom-file:not(:last-child) .custom-file-label, .input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group:not(.has-validation) > .form-control:not(:last-child),
.input-group:not(.has-validation) > .custom-select:not(:last-child),
.input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label,
.input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > .form-control:nth-last-child(n+3),
.input-group.has-validation > .custom-select:nth-last-child(n+3),
.input-group.has-validation > .custom-file:nth-last-child(n+3) .custom-file-label,
.input-group.has-validation > .custom-file:nth-last-child(n+3) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}
.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}
.input-group-prepend .btn:focus,
.input-group-append .btn:focus {
  z-index: 3;
}
.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 1rem 1rem;
  margin-bottom: 0;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.4285714286;
  color: #1d2126;
  text-align: center;
  white-space: nowrap;
  background-color: #f7f8fa;
  border: 1px solid #acb5c2;
  border-radius: 0;
}
.input-group-text input[type=radio],
.input-group-text input[type=checkbox] {
  margin-top: 0;
}

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px);
}

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.75rem;
  line-height: 1.5;
  border-radius: 0;
}

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.8rem + 2px);
}

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.4rem 1.2rem;
  font-size: 1.4rem;
  line-height: 1.5;
  border-radius: 0;
}

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 2rem;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .btn,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .input-group-text,
.input-group.has-validation > .input-group-append:nth-last-child(n+3) > .btn,
.input-group.has-validation > .input-group-append:nth-last-child(n+3) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 2rem;
  padding-left: 1.5rem;
  print-color-adjust: exact;
}

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.5rem;
  opacity: 0;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #0E72ED;
  background-color: #0E72ED;
}
.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0, 0 0 0 0.2rem rgba(14, 114, 237, 0.25);
}
.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #0E72ED;
}
.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: #b3d3fb;
  border-color: #b3d3fb;
}
.custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}
.custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}
.custom-control-label::before {
  position: absolute;
  top: 0.5rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #f7f8fa;
  border: 1px solid #adb5bd;
  box-shadow: 0;
}
.custom-control-label::after {
  position: absolute;
  top: 0.5rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: 50%/50% 50% no-repeat;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 4px;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #0E72ED;
  background-color: #0E72ED;
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(14, 114, 237, 0.5);
}
.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(14, 114, 237, 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(14, 114, 237, 0.5);
}

.custom-switch {
  padding-left: 2.25rem;
}
.custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: 0.5rem;
}
.custom-switch .custom-control-label::after {
  top: calc(0.5rem + 2px);
  left: calc(-2.25rem + 2px);
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: #adb5bd;
  border-radius: 0.5rem;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none;
  }
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #f7f8fa;
  transform: translateX(0.75rem);
}
.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(14, 114, 237, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: 4rem;
  padding: 1rem 2rem 1rem 1rem;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.4285714286;
  color: #1d2126;
  vertical-align: middle;
  background: #f7f8fa url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 1rem center/8px 10px no-repeat;
  border: 1px solid #acb5c2;
  border-radius: 4px;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  appearance: none;
}
.custom-select:focus {
  border-color: #0E72ED;
  outline: 0;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(14, 114, 237, 0.25);
}
.custom-select:focus::-ms-value {
  color: #1d2126;
  background-color: #f7f8fa;
}
.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 1rem;
  background-image: none;
}
.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}
.custom-select::-ms-expand {
  display: none;
}
.custom-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #1d2126;
}

.custom-select-sm {
  height: calc(1.5em + 0.8rem + 2px);
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
  padding-left: 1.2rem;
  font-size: 1.4rem;
}

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.75rem;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 4rem;
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: 4rem;
  margin: 0;
  overflow: hidden;
  opacity: 0;
}
.custom-file-input:focus ~ .custom-file-label {
  border-color: #0E72ED;
  box-shadow: 0 0 0 0.2rem rgba(14, 114, 237, 0.25);
}
.custom-file-input[disabled] ~ .custom-file-label, .custom-file-input:disabled ~ .custom-file-label {
  background-color: #e9ecef;
}
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}
.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: 4rem;
  padding: 1rem 1rem;
  overflow: hidden;
  font-weight: 400;
  line-height: 1.4285714286;
  color: #1d2126;
  background-color: #f7f8fa;
  border: 1px solid #acb5c2;
  border-radius: 0;
  box-shadow: 0;
}
.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.4285714286em + 2rem);
  padding: 1rem 1rem;
  line-height: 1.4285714286;
  color: #1d2126;
  content: "Browse";
  background-color: #f7f8fa;
  border-left: inherit;
  border-radius: 0 0 0 0;
}

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}
.custom-range:focus {
  outline: 0;
}
.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(14, 114, 237, 0.25);
}
.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(14, 114, 237, 0.25);
}
.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(14, 114, 237, 0.25);
}
.custom-range::-moz-focus-outer {
  border: 0;
}
.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #0E72ED;
  border: 0;
  border-radius: 1rem;
  box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1);
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    transition: none;
  }
}
.custom-range::-webkit-slider-thumb:active {
  background-color: #b3d3fb;
}
.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
  box-shadow: inset 0 0.25rem 0.25rem rgba(0, 0, 0, 0.1);
}
.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #0E72ED;
  border: 0;
  border-radius: 1rem;
  box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1);
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    transition: none;
  }
}
.custom-range::-moz-range-thumb:active {
  background-color: #b3d3fb;
}
.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
  box-shadow: inset 0 0.25rem 0.25rem rgba(0, 0, 0, 0.1);
}
.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #0E72ED;
  border: 0;
  border-radius: 1rem;
  box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1);
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    transition: none;
  }
}
.custom-range::-ms-thumb:active {
  background-color: #b3d3fb;
}
.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
  box-shadow: inset 0 0.25rem 0.25rem rgba(0, 0, 0, 0.1);
}
.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}
.custom-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-moz-range-track {
  cursor: default;
}
.custom-range:disabled::-ms-thumb {
  background-color: #adb5bd;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
  .custom-file-label,
  .custom-select {
    transition: none;
  }
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}
.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}
.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}
.nav-tabs .nav-link {
  margin-bottom: -1px;
  background-color: transparent;
  border: 1px solid transparent;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: #e9ecef #e9ecef #dee2e6;
}
.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 4px;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #0E72ED;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 4px;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 600;
}

.alert-dismissible {
  padding-right: 4.6rem;
}
.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: #073b7b;
  background-color: #cfe3fb;
  border-color: #bcd8fa;
}
.alert-primary hr {
  border-top-color: #a4caf8;
}
.alert-primary .alert-link {
  color: #04244b;
}

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db;
}
.alert-secondary hr {
  border-top-color: #c8cbcf;
}
.alert-secondary .alert-link {
  color: #202326;
}

.alert-success {
  color: #00451f;
  background-color: #cce6d8;
  border-color: #b8ddc8;
}
.alert-success hr {
  border-top-color: #a7d5bb;
}
.alert-success .alert-link {
  color: #001208;
}

.alert-info {
  color: #7a8080;
  background-color: #fbfdfd;
  border-color: #f9fdfd;
}
.alert-info hr {
  border-top-color: #e6f7f7;
}
.alert-info .alert-link {
  color: #616666;
}

.alert-warning {
  color: #823513;
  background-color: #fee0d3;
  border-color: #fed4c2;
}
.alert-warning hr {
  border-top-color: #fec2a9;
}
.alert-warning .alert-link {
  color: #56230c;
}

.alert-danger {
  color: #7a061f;
  background-color: #fbced8;
  border-color: #f9bbc8;
}
.alert-danger hr {
  border-top-color: #f7a4b5;
}
.alert-danger .alert-link {
  color: #490413;
}

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe;
}
.alert-light hr {
  border-top-color: #ececf6;
}
.alert-light .alert-link {
  color: #686868;
}

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca;
}
.alert-dark hr {
  border-top-color: #b9bbbe;
}
.alert-dark .alert-link {
  color: #040505;
}

.alert-logo {
  color: #244049;
  background-color: #dae5e8;
  border-color: #cbdadf;
}
.alert-logo hr {
  border-top-color: #bbcfd5;
}
.alert-logo .alert-link {
  color: #132227;
}

.alert-black {
  color: #0f1114;
  background-color: #d2d3d4;
  border-color: #c0c1c2;
}
.alert-black hr {
  border-top-color: #b3b4b5;
}
.alert-black .alert-link {
  color: black;
}

.alert-gray-1 {
  color: #1e2228;
  background-color: #d8d9db;
  border-color: #c8cacd;
}
.alert-gray-1 hr {
  border-top-color: #bbbdc1;
}
.alert-gray-1 .alert-link {
  color: #08090b;
}

.alert-gray-2 {
  color: #2d333c;
  background-color: #dde0e3;
  border-color: #d0d3d8;
}
.alert-gray-2 hr {
  border-top-color: #c2c6cc;
}
.alert-gray-2 .alert-link {
  color: #171a1f;
}

.alert-gray-3 {
  color: #3c4550;
  background-color: #e3e6eb;
  border-color: #d8dde2;
}
.alert-gray-3 hr {
  border-top-color: #c9d0d7;
}
.alert-gray-3 .alert-link {
  color: #262c33;
}

.alert-gray-4 {
  color: #595e65;
  background-color: #eef0f3;
  border-color: #e8eaee;
}
.alert-gray-4 hr {
  border-top-color: #d9dde3;
}
.alert-gray-4 .alert-link {
  color: #41454a;
}

.alert-gray-5 {
  color: #6e7175;
  background-color: #f6f8f9;
  border-color: #f3f5f7;
}
.alert-gray-5 hr {
  border-top-color: #e4e8ed;
}
.alert-gray-5 .alert-link {
  color: #55585b;
}

.alert-gray-6 {
  color: #77787b;
  background-color: #fafafb;
  border-color: #f7f8fa;
}
.alert-gray-6 hr {
  border-top-color: #e7eaf0;
}
.alert-gray-6 .alert-link {
  color: #5e5f61;
}

.alert-gray-7 {
  color: #808182;
  background-color: #fdfefe;
  border-color: #fdfdfe;
}
.alert-gray-7 hr {
  border-top-color: #ececf6;
}
.alert-gray-7 .alert-link {
  color: #676868;
}

.alert-yellow {
  color: #846b00;
  background-color: #fff5cc;
  border-color: #fef1b8;
}
.alert-yellow hr {
  border-top-color: #feec9f;
}
.alert-yellow .alert-link {
  color: #514200;
}

.alert-kelp {
  color: #025637;
  background-color: #cdede1;
  border-color: #b8e6d5;
}
.alert-kelp hr {
  border-top-color: #a5dfca;
}
.alert-kelp .alert-link {
  color: #012417;
}

.alert-high-tide {
  color: #062865;
  background-color: #cedbf3;
  border-color: #bbcdee;
}
.alert-high-tide hr {
  border-top-color: #a7bee9;
}
.alert-high-tide .alert-link {
  color: #031535;
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}
.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 1.05rem;
  background-color: #e9ecef;
  border-radius: 4px;
  box-shadow: inset 0 0.1rem 0.1rem rgba(0, 0, 0, 0.1);
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #0E72ED;
  transition: width 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

.close {
  float: right;
  font-size: 2.1rem;
  font-weight: 600;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}
.close:hover {
  color: #000;
  text-decoration: none;
}
.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
  opacity: 0.75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
}

a.close.disabled {
  pointer-events: none;
}

.modal-open {
  overflow: hidden;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  display: flex;
  max-height: 100%;
}
.modal-dialog-scrollable .modal-content {
  max-height: 100vh;
  overflow: hidden;
}
.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: 100%;
}
.modal-dialog-centered::before {
  display: block;
  height: 100vh;
  height: min-content;
  content: "";
}
.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}
.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #748499;
  border-radius: 4px;
  box-shadow: none;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.8;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 14px 60px 14px 60px;
  border-bottom: 0 solid #dee2e6;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.modal-header .close {
  padding: 14px 60px 14px 60px;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.4285714286;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 2rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 1.75rem;
  border-top: 0 solid #dee2e6;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.modal-footer > * {
  margin: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 100%;
    margin: 1.75rem auto;
  }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
    height: min-content;
  }
  .modal-content {
    box-shadow: none;
  }
  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
/*rtl:begin:ignore*/
.tooltip {
  position: absolute;
  z-index: 5051;
  display: block;
  margin: 0;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.4285714286;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: 1.225rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 0.9;
}
.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[x-placement^=top] {
  padding: 0.4rem 0;
}
.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^=top] .arrow {
  bottom: 0;
}
.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^=top] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #181818;
}

.bs-tooltip-right, .bs-tooltip-auto[x-placement^=right] {
  padding: 0 0.4rem;
}
.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^=right] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^=right] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #181818;
}

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^=bottom] {
  padding: 0.4rem 0;
}
.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^=bottom] .arrow {
  top: 0;
}
.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #181818;
}

.bs-tooltip-left, .bs-tooltip-auto[x-placement^=left] {
  padding: 0 0.4rem;
}
.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^=left] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^=left] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #181818;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #181818;
  border-radius: 2px;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5051;
  display: block;
  max-width: 276px;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.4285714286;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: 1.225rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d4dae1;
  border-radius: 0.3rem;
  box-shadow: 0 0 10px 0 rgba(38, 29, 29, 0.1);
}
.popover .arrow {
  position: absolute;
  display: block;
  width: 20px;
  height: 10px;
  margin: 0 0.3rem;
}
.popover .arrow::before, .popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top, .bs-popover-auto[x-placement^=top] {
  margin-bottom: 10px;
}
.bs-popover-top > .arrow, .bs-popover-auto[x-placement^=top] > .arrow {
  bottom: -11px;
}
.bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^=top] > .arrow::before {
  bottom: 0;
  border-width: 10px 10px 0;
  border-top-color: #d4dae1;
}
.bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^=top] > .arrow::after {
  bottom: 1px;
  border-width: 10px 10px 0;
  border-top-color: #ffffff;
}

.bs-popover-right, .bs-popover-auto[x-placement^=right] {
  margin-left: 10px;
}
.bs-popover-right > .arrow, .bs-popover-auto[x-placement^=right] > .arrow {
  left: -11px;
  width: 10px;
  height: 20px;
  margin: 0.3rem 0;
}
.bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^=right] > .arrow::before {
  left: 0;
  border-width: 10px 10px 10px 0;
  border-right-color: #d4dae1;
}
.bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^=right] > .arrow::after {
  left: 1px;
  border-width: 10px 10px 10px 0;
  border-right-color: #ffffff;
}

.bs-popover-bottom, .bs-popover-auto[x-placement^=bottom] {
  margin-top: 10px;
}
.bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^=bottom] > .arrow {
  top: -11px;
}
.bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^=bottom] > .arrow::before {
  top: 0;
  border-width: 0 10px 10px 10px;
  border-bottom-color: #d4dae1;
}
.bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^=bottom] > .arrow::after {
  top: 1px;
  border-width: 0 10px 10px 10px;
  border-bottom-color: #ffffff;
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 20px;
  margin-left: -10px;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left, .bs-popover-auto[x-placement^=left] {
  margin-right: 10px;
}
.bs-popover-left > .arrow, .bs-popover-auto[x-placement^=left] > .arrow {
  right: -11px;
  width: 10px;
  height: 20px;
  margin: 0.3rem 0;
}
.bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^=left] > .arrow::before {
  right: 0;
  border-width: 10px 0 10px 10px;
  border-left-color: #d4dae1;
}
.bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^=left] > .arrow::after {
  right: 1px;
  border-width: 10px 0 10px 10px;
  border-left-color: #ffffff;
}

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1.4rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 15px 15px;
  color: #212529;
}

/*rtl:end:ignore*/
.btn[disabled] {
  cursor: initial;
}

.btn-primary[disabled], .btn-danger[disabled] {
  background-color: #e4e7ec;
  border-color: #e4e7ec;
  color: #748499;
}
.btn-primary.night[disabled], .btn-danger.night[disabled] {
  border-color: #748499;
  background-color: #748499;
  color: white;
  opacity: 0.6;
}

.react-app .btn-secondary:hover:not([disabled]), .multiple-react-app .btn-secondary:hover:not([disabled]), .popover .btn-secondary:hover:not([disabled]), .modal .btn-secondary:hover:not([disabled]), .react-app .btn-secondary, .multiple-react-app .btn-secondary, .popover .btn-secondary, .modal .btn-secondary {
  color: #0E72ED;
  border-color: #0E72ED;
}
.react-app .btn-secondary:hover:not([disabled]), .multiple-react-app .btn-secondary:hover:not([disabled]), .popover .btn-secondary:hover:not([disabled]), .modal .btn-secondary:hover:not([disabled]), .react-app .btn-secondary:hover, .multiple-react-app .btn-secondary:hover, .popover .btn-secondary:hover, .modal .btn-secondary:hover {
  color: #fff;
  background-color: #0E72ED;
  border-color: #0E72ED;
}
.react-app .btn-secondary:focus:hover:not([disabled]), .multiple-react-app .btn-secondary:focus:hover:not([disabled]), .popover .btn-secondary:focus:hover:not([disabled]), .modal .btn-secondary:focus:hover:not([disabled]), .react-app .btn-secondary:focus, .multiple-react-app .btn-secondary:focus, .popover .btn-secondary:focus, .modal .btn-secondary:focus, .react-app .focus.btn-secondary:hover:not([disabled]), .multiple-react-app .focus.btn-secondary:hover:not([disabled]), .popover .focus.btn-secondary:hover:not([disabled]), .modal .focus.btn-secondary:hover:not([disabled]), .react-app .focus.btn-secondary, .multiple-react-app .focus.btn-secondary, .popover .focus.btn-secondary, .modal .focus.btn-secondary {
  box-shadow: 0 0 0 0.2rem rgba(14, 114, 237, 0.5);
}
.react-app .disabled.btn-secondary:hover:not([disabled]), .multiple-react-app .disabled.btn-secondary:hover:not([disabled]), .popover .disabled.btn-secondary:hover:not([disabled]), .modal .disabled.btn-secondary:hover:not([disabled]), .react-app .disabled.btn-secondary, .multiple-react-app .disabled.btn-secondary, .popover .disabled.btn-secondary, .modal .disabled.btn-secondary, .react-app .btn-secondary:disabled:hover:not([disabled]), .multiple-react-app .btn-secondary:disabled:hover:not([disabled]), .popover .btn-secondary:disabled:hover:not([disabled]), .modal .btn-secondary:disabled:hover:not([disabled]), .react-app .btn-secondary:disabled, .multiple-react-app .btn-secondary:disabled, .popover .btn-secondary:disabled, .modal .btn-secondary:disabled {
  color: #0E72ED;
  background-color: transparent;
}
.react-app .btn-secondary:not(:disabled):not(.disabled):active, .multiple-react-app .btn-secondary:not(:disabled):not(.disabled):active, .popover .btn-secondary:not(:disabled):not(.disabled):active, .modal .btn-secondary:not(:disabled):not(.disabled):active, .react-app .btn-secondary:not(:disabled):not(.disabled).active, .multiple-react-app .btn-secondary:not(:disabled):not(.disabled).active, .popover .btn-secondary:not(:disabled):not(.disabled).active, .modal .btn-secondary:not(:disabled):not(.disabled).active, .react-app .show > .dropdown-toggle.btn-secondary, .multiple-react-app .show > .dropdown-toggle.btn-secondary, .popover .show > .dropdown-toggle.btn-secondary, .modal .show > .dropdown-toggle.btn-secondary {
  color: #fff;
  background-color: #0E72ED;
  border-color: #0E72ED;
}
.react-app .btn-secondary:not(:disabled):not(.disabled):active:focus, .multiple-react-app .btn-secondary:not(:disabled):not(.disabled):active:focus, .popover .btn-secondary:not(:disabled):not(.disabled):active:focus, .modal .btn-secondary:not(:disabled):not(.disabled):active:focus, .react-app .btn-secondary:not(:disabled):not(.disabled).active:focus, .multiple-react-app .btn-secondary:not(:disabled):not(.disabled).active:focus, .popover .btn-secondary:not(:disabled):not(.disabled).active:focus, .modal .btn-secondary:not(:disabled):not(.disabled).active:focus, .react-app .show > .dropdown-toggle.btn-secondary:focus, .multiple-react-app .show > .dropdown-toggle.btn-secondary:focus, .popover .show > .dropdown-toggle.btn-secondary:focus, .modal .show > .dropdown-toggle.btn-secondary:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(14, 114, 237, 0.5);
}

/*
  2020-05-03 at the time of writing:
  NovoEd wants spacing between tooltip/popover target and this is done in the offset in popper config
  however,
  1) react bootstrap 1.0.1 depends on twitter bootstrap 4 & popper.js 2
    popper.js 2 does not allow for margins on the tooltip/popover
    twitter bootstrap 4 is expecting popper.js 1 and does specify margin for popover, so we are setting it to 0 here
  2) https://github.com/react-bootstrap/react-bootstrap/pull/5112 technically patches this
    but because NovoEd has added 'bs4-' to all the component names and the fix expects '.popover' it fails to work
 */
.popover {
  border-radius: 5px;
}
.popover.bs-popover-top, .popover.bs-popover-auto[x-placement^=top], .popover.bs-popover-bottom, .popover.bs-popover-auto[x-placement^=bottom], .popover.bs-popover-left, .popover.bs-popover-auto[x-placement^=left], .popover.bs-popover-right, .popover.bs-popover-auto[x-placement^=right] {
  margin: 0;
}
.popover .arrow {
  margin: 0;
}

.dropdown-menu {
  margin: 0;
}

/* This https://projects.invisionapp.com/d/main?origin=v7#/console/5847843/125808276/inspect?scrollOffset=1356
 * says our 'secondary' style is actually an outlined version of the primary styles */
.react-app, .multiple-react-app, .popover, .modal {
  /* Hide arrows from number input - Chrome, Safari, Edge, Opera */
  /* Hide arrows from number input - Firefox */
}
.react-app .btn-sm, .react-app .btn-group-sm > .btn, .multiple-react-app .btn-sm, .multiple-react-app .btn-group-sm > .btn, .popover .btn-sm, .popover .btn-group-sm > .btn, .modal .btn-sm, .modal .btn-group-sm > .btn {
  border-radius: 4px;
}
.react-app .btn-secondary, .multiple-react-app .btn-secondary, .popover .btn-secondary, .modal .btn-secondary {
  background-color: transparent;
  font-weight: 700;
}
.react-app .btn-secondary.night, .multiple-react-app .btn-secondary.night, .popover .btn-secondary.night, .modal .btn-secondary.night {
  border: white 1px solid !important;
  color: white;
  background-color: transparent;
}
.react-app .btn-secondary.night:hover:not([disabled]), .multiple-react-app .btn-secondary.night:hover:not([disabled]), .popover .btn-secondary.night:hover:not([disabled]), .modal .btn-secondary.night:hover:not([disabled]) {
  border-color: white;
  color: white;
  background-color: rgba(255, 255, 255, 0.1);
}
.react-app .btn-secondary:hover:not([disabled]), .multiple-react-app .btn-secondary:hover:not([disabled]), .popover .btn-secondary:hover:not([disabled]), .modal .btn-secondary:hover:not([disabled]) {
  background-color: rgba(14, 114, 237, 0.1);
  color: #0E72ED;
}
.react-app .btn-secondary[disabled], .multiple-react-app .btn-secondary[disabled], .popover .btn-secondary[disabled], .modal .btn-secondary[disabled] {
  border-color: #acb5c2;
  color: #acb5c2;
  font-weight: 400;
}
.react-app .btn-danger:hover:not([disabled]), .multiple-react-app .btn-danger:hover:not([disabled]), .popover .btn-danger:hover:not([disabled]), .modal .btn-danger:hover:not([disabled]) {
  background-color: #D9234A;
  border-color: #D9234A;
  color: white;
}
.react-app .btn-sm, .react-app .btn-group-sm > .btn, .multiple-react-app .btn-sm, .multiple-react-app .btn-group-sm > .btn, .popover .btn-sm, .popover .btn-group-sm > .btn, .modal .btn-sm, .modal .btn-group-sm > .btn {
  border-radius: 4px;
}
.react-app .btn-primary:hover:not([disabled]), .multiple-react-app .btn-primary:hover:not([disabled]), .popover .btn-primary:hover:not([disabled]), .modal .btn-primary:hover:not([disabled]) {
  background-color: #0b4dc2;
}
.react-app .btn-primary, .react-app .btn-secondary, .react-app .btn-danger, .multiple-react-app .btn-primary, .multiple-react-app .btn-secondary, .multiple-react-app .btn-danger, .popover .btn-primary, .popover .btn-secondary, .popover .btn-danger, .modal .btn-primary, .modal .btn-secondary, .modal .btn-danger {
  font-weight: 600;
}
.react-app .btn-primary[disabled], .react-app .btn-secondary[disabled], .react-app .btn-danger[disabled], .multiple-react-app .btn-primary[disabled], .multiple-react-app .btn-secondary[disabled], .multiple-react-app .btn-danger[disabled], .popover .btn-primary[disabled], .popover .btn-secondary[disabled], .popover .btn-danger[disabled], .modal .btn-primary[disabled], .modal .btn-secondary[disabled], .modal .btn-danger[disabled] {
  font-weight: 400;
}
.react-app .form-check-label, .multiple-react-app .form-check-label, .popover .form-check-label, .modal .form-check-label {
  display: inline;
  font-weight: 400;
}
.react-app .form-control:not(:last-child), .multiple-react-app .form-control:not(:last-child), .popover .form-control:not(:last-child), .modal .form-control:not(:last-child) {
  border-right: 0;
}
.react-app input[type=checkbox] + label, .react-app input[type=radio] + label, .multiple-react-app input[type=checkbox] + label, .multiple-react-app input[type=radio] + label, .popover input[type=checkbox] + label, .popover input[type=radio] + label, .modal input[type=checkbox] + label, .modal input[type=radio] + label {
  font-weight: 400;
}
.react-app input[type=number]::-webkit-outer-spin-button,
.react-app input[type=number]::-webkit-inner-spin-button, .multiple-react-app input[type=number]::-webkit-outer-spin-button,
.multiple-react-app input[type=number]::-webkit-inner-spin-button, .popover input[type=number]::-webkit-outer-spin-button,
.popover input[type=number]::-webkit-inner-spin-button, .modal input[type=number]::-webkit-outer-spin-button,
.modal input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.react-app input[type=number], .multiple-react-app input[type=number], .popover input[type=number], .modal input[type=number] {
  -moz-appearance: textfield;
}
.react-app textarea, .multiple-react-app textarea, .popover textarea, .modal textarea {
  resize: none;
}
.react-app .input-group, .multiple-react-app .input-group, .popover .input-group, .modal .input-group {
  flex-wrap: nowrap;
  /* Only apply invalid to the border-color since we still need
  a white bkg on focus even when the input is invalid */
}
.react-app .input-group .input-group-append > .input-group-text, .multiple-react-app .input-group .input-group-append > .input-group-text, .popover .input-group .input-group-append > .input-group-text, .modal .input-group .input-group-append > .input-group-text {
  padding-left: 0;
}
.react-app .input-group.invalid, .react-app .input-group.invalid input, .react-app .input-group.invalid textarea, .multiple-react-app .input-group.invalid, .multiple-react-app .input-group.invalid input, .multiple-react-app .input-group.invalid textarea, .popover .input-group.invalid, .popover .input-group.invalid input, .popover .input-group.invalid textarea, .modal .input-group.invalid, .modal .input-group.invalid input, .modal .input-group.invalid textarea {
  border-color: #eb0c3b;
}
.react-app .input-group.invalid ~ .input-group-append > .input-group-text, .react-app .input-group.invalid input ~ .input-group-append > .input-group-text, .react-app .input-group.invalid textarea ~ .input-group-append > .input-group-text, .multiple-react-app .input-group.invalid ~ .input-group-append > .input-group-text, .multiple-react-app .input-group.invalid input ~ .input-group-append > .input-group-text, .multiple-react-app .input-group.invalid textarea ~ .input-group-append > .input-group-text, .popover .input-group.invalid ~ .input-group-append > .input-group-text, .popover .input-group.invalid input ~ .input-group-append > .input-group-text, .popover .input-group.invalid textarea ~ .input-group-append > .input-group-text, .modal .input-group.invalid ~ .input-group-append > .input-group-text, .modal .input-group.invalid input ~ .input-group-append > .input-group-text, .modal .input-group.invalid textarea ~ .input-group-append > .input-group-text {
  border-color: #eb0c3b;
}
.react-app .input-group input:focus ~ .input-group-append > .input-group-text, .react-app .input-group textarea:focus ~ .input-group-append > .input-group-text, .multiple-react-app .input-group input:focus ~ .input-group-append > .input-group-text, .multiple-react-app .input-group textarea:focus ~ .input-group-append > .input-group-text, .popover .input-group input:focus ~ .input-group-append > .input-group-text, .popover .input-group textarea:focus ~ .input-group-append > .input-group-text, .modal .input-group input:focus ~ .input-group-append > .input-group-text, .modal .input-group textarea:focus ~ .input-group-append > .input-group-text {
  background-color: white;
}
.react-app .input-group:not(.invalid) input:focus ~ .input-group-append > .input-group-text, .react-app .input-group:not(.invalid) textarea:focus ~ .input-group-append > .input-group-text, .multiple-react-app .input-group:not(.invalid) input:focus ~ .input-group-append > .input-group-text, .multiple-react-app .input-group:not(.invalid) textarea:focus ~ .input-group-append > .input-group-text, .popover .input-group:not(.invalid) input:focus ~ .input-group-append > .input-group-text, .popover .input-group:not(.invalid) textarea:focus ~ .input-group-append > .input-group-text, .modal .input-group:not(.invalid) input:focus ~ .input-group-append > .input-group-text, .modal .input-group:not(.invalid) textarea:focus ~ .input-group-append > .input-group-text {
  border-color: #0E72ED;
}
.react-app .input-group .input-group-text, .multiple-react-app .input-group .input-group-text, .popover .input-group .input-group-text, .modal .input-group .input-group-text {
  border-left: 0;
  color: #748499;
}
.react-app .input-group input:focus, .multiple-react-app .input-group input:focus, .popover .input-group input:focus, .modal .input-group input:focus {
  outline: none;
}
.react-app .input-group .input-group-text:last-of-type, .multiple-react-app .input-group .input-group-text:last-of-type, .popover .input-group .input-group-text:last-of-type, .modal .input-group .input-group-text:last-of-type {
  line-height: 100%;
}
.react-app .input-group.readonly input[type=text][readonly], .react-app .input-group.readonly textarea[readonly], .multiple-react-app .input-group.readonly input[type=text][readonly], .multiple-react-app .input-group.readonly textarea[readonly], .popover .input-group.readonly input[type=text][readonly], .popover .input-group.readonly textarea[readonly], .modal .input-group.readonly input[type=text][readonly], .modal .input-group.readonly textarea[readonly] {
  border-color: #acb5c2;
  background-color: #f7f8fa;
}
.react-app .input-group.readonly input[type=text][readonly] ~ .input-group-append > .input-group-text, .react-app .input-group.readonly textarea[readonly] ~ .input-group-append > .input-group-text, .multiple-react-app .input-group.readonly input[type=text][readonly] ~ .input-group-append > .input-group-text, .multiple-react-app .input-group.readonly textarea[readonly] ~ .input-group-append > .input-group-text, .popover .input-group.readonly input[type=text][readonly] ~ .input-group-append > .input-group-text, .popover .input-group.readonly textarea[readonly] ~ .input-group-append > .input-group-text, .modal .input-group.readonly input[type=text][readonly] ~ .input-group-append > .input-group-text, .modal .input-group.readonly textarea[readonly] ~ .input-group-append > .input-group-text {
  border-color: #acb5c2;
  background-color: #f7f8fa;
}

.modal-dialog {
  margin: 0;
  max-width: none;
}
.modal-dialog .modal-header {
  color: #1d2126;
  background-color: #e4e7ec;
  text-align: center;
  align-items: center;
  height: 60px;
  min-height: 60px;
  padding: 0;
  margin-top: 0;
}
.modal-dialog .modal-header .modal-title {
  height: 60px;
  font-size: 24px;
  margin-top: 0;
  line-height: 35px;
  width: 100%;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 300;
  margin-bottom: 0;
  align-self: stretch;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-dialog .modal-header .close {
  padding-right: 10px;
  opacity: 1;
}
.modal-dialog .modal-header .close:focus, .modal-dialog .modal-header .close:active {
  outline: 0;
}
.modal-dialog .modal-header .close:hover {
  cursor: pointer;
  opacity: 1;
}
.modal-dialog .modal-header .close:hover > span:first-of-type::after {
  color: #0E72ED !important;
}
.modal-dialog .modal-header .close > span:first-of-type {
  display: block;
  font-size: 0px;
  color: #000;
  white-space: nowrap;
  text-overflow: ellipsis;
  height: 24px;
}
.modal-dialog .modal-header .close > span:first-of-type::after {
  color: transparent;
  font-size: 24px;
  letter-spacing: normal;
  color: #748499 !important;
  cursor: pointer;
  font-family: "novoed-icons";
  content: "\e02a";
  visibility: visible;
  display: block;
}
.modal-dialog .modal-body {
  padding: 20px;
  overflow-y: auto;
  height: 100%;
}
.modal-dialog .modal-body .bs4-btn {
  line-height: inherit;
}

[data-popper-escaped=true],
[data-popper-reference-hidden=true] {
  visibility: hidden;
  pointer-events: none;
}