/*rtl:begin:ignore*/
/*!
 * froala_editor v2.8.4 (https://www.froala.com/wysiwyg-editor)
 * License https://froala.com/wysiwyg-editor/terms/
 * Copyright 2014-2018 Froala Labs
 */
.clearfix::after {
  clear: both;
  display: block;
  content: "";
  height: 0;
}

.hide-by-clipping {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.fr-popup .fr-colors-tabs {
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 1px 1px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 1px 1px rgba(0, 0, 0, 0.16);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 1px 1px rgba(0, 0, 0, 0.16);
  margin-bottom: 5px;
  line-height: 16px;
  margin-left: -2px;
  margin-right: -2px;
}

.fr-popup .fr-colors-tabs .fr-colors-tab {
  display: inline-block;
  width: 50%;
  cursor: pointer;
  text-align: center;
  color: #222;
  font-size: 13px;
  padding: 8px 0;
  position: relative;
}

.fr-popup .fr-colors-tabs .fr-colors-tab:hover, .fr-popup .fr-colors-tabs .fr-colors-tab:focus {
  color: #1e88e5;
}

.fr-popup .fr-colors-tabs .fr-colors-tab[data-param1=background]::after {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background: #1e88e5;
  content: "";
  -webkit-transition: transform 0.2s ease 0s;
  -moz-transition: transform 0.2s ease 0s;
  -ms-transition: transform 0.2s ease 0s;
  -o-transition: transform 0.2s ease 0s;
}

.fr-popup .fr-colors-tabs .fr-colors-tab.fr-selected-tab {
  color: #1e88e5;
}

.fr-popup .fr-colors-tabs .fr-colors-tab.fr-selected-tab[data-param1=text] ~ [data-param1=background]::after {
  -webkit-transform: translate3d(-100%, 0, 0);
  -moz-transform: translate3d(-100%, 0, 0);
  -ms-transform: translate3d(-100%, 0, 0);
  -o-transform: translate3d(-100%, 0, 0);
}

.fr-popup .fr-color-hex-layer {
  width: 100%;
  margin: 0;
  padding: 10px;
}

.fr-popup .fr-color-hex-layer .fr-input-line {
  float: left;
  width: calc(100% - 50px);
  padding: 8px 0 0;
}

.fr-popup .fr-color-hex-layer .fr-action-buttons {
  float: right;
  width: 50px;
}

.fr-popup .fr-color-hex-layer .fr-action-buttons button {
  background-color: #1e88e5;
  color: #FFF;
  border-radius: 2px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  font-size: 13px;
  height: 32px;
}

.fr-popup .fr-color-hex-layer .fr-action-buttons button:hover {
  background-color: #166dba;
  color: #FFF;
}

.fr-popup .fr-separator + .fr-colors-tabs {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  margin-left: 2px;
  margin-right: 2px;
}

.fr-popup .fr-color-set {
  line-height: 0;
  display: none;
}

.fr-popup .fr-color-set.fr-selected-set {
  display: block;
}

.fr-popup .fr-color-set > span {
  display: inline-block;
  width: 32px;
  height: 32px;
  position: relative;
  z-index: 1;
}

.fr-popup .fr-color-set > span > i, .fr-popup .fr-color-set > span > svg {
  text-align: center;
  line-height: 32px;
  height: 32px;
  width: 32px;
  font-size: 13px;
  position: absolute;
  bottom: 0;
  cursor: default;
  left: 0;
}

.fr-popup .fr-color-set > span .fr-selected-color {
  color: #fff;
  font-family: FontAwesome;
  font-size: 13px;
  font-weight: 400;
  line-height: 32px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  text-align: center;
  cursor: default;
}

.fr-popup .fr-color-set > span:hover, .fr-popup .fr-color-set > span:focus {
  outline: 1px solid #222;
  z-index: 2;
}

.fr-rtl .fr-popup .fr-colors-tabs .fr-colors-tab.fr-selected-tab[data-param1=text] ~ [data-param1=background]::after {
  -webkit-transform: translate3d(100%, 0, 0);
  -moz-transform: translate3d(100%, 0, 0);
  -ms-transform: translate3d(100%, 0, 0);
  -o-transform: translate3d(100%, 0, 0);
}

/*!
 * froala_editor v2.8.4 (https://www.froala.com/wysiwyg-editor)
 * License https://froala.com/wysiwyg-editor/terms/
 * Copyright 2014-2018 Froala Labs
 */
.clearfix::after {
  clear: both;
  display: block;
  content: "";
  height: 0;
}

.hide-by-clipping {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.fr-drag-helper {
  background: #1e88e5;
  height: 2px;
  margin-top: -1px;
  -webkit-opacity: 0.2;
  -moz-opacity: 0.2;
  opacity: 0.2;
  -ms-filter: "alpha(Opacity=0)";
  position: absolute;
  z-index: 2147483640;
  display: none;
}

.fr-drag-helper.fr-visible {
  display: block;
}

.fr-dragging {
  -webkit-opacity: 0.4;
  -moz-opacity: 0.4;
  opacity: 0.4;
  -ms-filter: "alpha(Opacity=0)";
}

/*!
 * froala_editor v2.8.4 (https://www.froala.com/wysiwyg-editor)
 * License https://froala.com/wysiwyg-editor/terms/
 * Copyright 2014-2018 Froala Labs
 */
.clearfix::after {
  clear: both;
  display: block;
  content: "";
  height: 0;
}

.hide-by-clipping {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.fr-element img {
  cursor: pointer;
}

.fr-image-resizer {
  position: absolute;
  border: solid 1px #1e88e5;
  display: none;
  user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

.fr-image-resizer.fr-active {
  display: block;
}

.fr-image-resizer .fr-handler {
  display: block;
  position: absolute;
  background: #1e88e5;
  border: solid 1px #fff;
  z-index: 4;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.fr-image-resizer .fr-handler.fr-hnw {
  cursor: nw-resize;
}

.fr-image-resizer .fr-handler.fr-hne {
  cursor: ne-resize;
}

.fr-image-resizer .fr-handler.fr-hsw {
  cursor: sw-resize;
}

.fr-image-resizer .fr-handler.fr-hse {
  cursor: se-resize;
}

.fr-image-resizer .fr-handler {
  width: 12px;
  height: 12px;
}

.fr-image-resizer .fr-handler.fr-hnw {
  left: -6px;
  top: -6px;
}

.fr-image-resizer .fr-handler.fr-hne {
  right: -6px;
  top: -6px;
}

.fr-image-resizer .fr-handler.fr-hsw {
  left: -6px;
  bottom: -6px;
}

.fr-image-resizer .fr-handler.fr-hse {
  right: -6px;
  bottom: -6px;
}

@media (min-width: 1200px) {
  .fr-image-resizer .fr-handler {
    width: 10px;
    height: 10px;
  }
  .fr-image-resizer .fr-handler.fr-hnw {
    left: -5px;
    top: -5px;
  }
  .fr-image-resizer .fr-handler.fr-hne {
    right: -5px;
    top: -5px;
  }
  .fr-image-resizer .fr-handler.fr-hsw {
    left: -5px;
    bottom: -5px;
  }
  .fr-image-resizer .fr-handler.fr-hse {
    right: -5px;
    bottom: -5px;
  }
}
.fr-image-overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 2147483640;
  display: none;
}

.fr-popup .fr-image-upload-layer {
  border: dashed 2px #bdbdbd;
  padding: 25px 0;
  position: relative;
  font-size: 14px;
  letter-spacing: 1px;
  line-height: 140%;
  text-align: center;
}

.fr-popup .fr-image-upload-layer:hover {
  background: #ebebeb;
}

.fr-popup .fr-image-upload-layer.fr-drop {
  background: #ebebeb;
  border-color: #1e88e5;
}

.fr-popup .fr-image-upload-layer .fr-form {
  -webkit-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
  -ms-filter: "alpha(Opacity=0)";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2147483640;
  overflow: hidden;
  margin: 0 !important;
  padding: 0 !important;
  width: 100% !important;
}

.fr-popup .fr-image-upload-layer .fr-form input {
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 500%;
  height: 100%;
  margin: 0;
  font-size: 400px;
}

.fr-popup .fr-image-progress-bar-layer > h3 {
  font-size: 16px;
  margin: 10px 0;
  font-weight: 400;
}

.fr-popup .fr-image-progress-bar-layer > div.fr-action-buttons {
  display: none;
}

.fr-popup .fr-image-progress-bar-layer > div.fr-loader {
  background: #bcdbf7;
  height: 10px;
  width: 100%;
  margin-top: 20px;
  overflow: hidden;
  position: relative;
}

.fr-popup .fr-image-progress-bar-layer > div.fr-loader span {
  display: block;
  height: 100%;
  width: 0;
  background: #1e88e5;
  -webkit-transition: width 0.2s ease 0s;
  -moz-transition: width 0.2s ease 0s;
  -ms-transition: width 0.2s ease 0s;
  -o-transition: width 0.2s ease 0s;
}

.fr-popup .fr-image-progress-bar-layer > div.fr-loader.fr-indeterminate span {
  width: 30% !important;
  position: absolute;
  top: 0;
  -webkit-animation: loading 2s linear infinite;
  -moz-animation: loading 2s linear infinite;
  -o-animation: loading 2s linear infinite;
  animation: loading 2s linear infinite;
}

.fr-popup .fr-image-progress-bar-layer.fr-error > div.fr-loader {
  display: none;
}

.fr-popup .fr-image-progress-bar-layer.fr-error > div.fr-action-buttons {
  display: block;
}

.fr-image-size-layer .fr-image-group .fr-input-line {
  width: calc(50% - 5px);
  display: inline-block;
}

.fr-image-size-layer .fr-image-group .fr-input-line + .fr-input-line {
  margin-left: 10px;
}

.fr-uploading {
  -webkit-opacity: 0.4;
  -moz-opacity: 0.4;
  opacity: 0.4;
  -ms-filter: "alpha(Opacity=0)";
}

@keyframes loading {
  from {
    left: -25%;
  }
  to {
    left: 100%;
  }
}
@-webkit-keyframes loading {
  from {
    left: -25%;
  }
  to {
    left: 100%;
  }
}
@-moz-keyframes loading {
  from {
    left: -25%;
  }
  to {
    left: 100%;
  }
}
@-o-keyframes loading {
  from {
    left: -25%;
  }
  to {
    left: 100%;
  }
}
/*!
 * froala_editor v2.8.4 (https://www.froala.com/wysiwyg-editor)
 * License https://froala.com/wysiwyg-editor/terms/
 * Copyright 2014-2018 Froala Labs
 */
.clearfix::after {
  clear: both;
  display: block;
  content: "";
  height: 0;
}

.hide-by-clipping {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

textarea.fr-code {
  display: none;
  width: 100%;
  resize: none;
  -moz-resize: none;
  -webkit-resize: none;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border: 0;
  padding: 10px;
  margin: 0;
  font-family: "Courier New", monospace;
  font-size: 14px;
  background: #fff;
  color: #000;
  outline: 0;
}

.fr-box.fr-rtl textarea.fr-code {
  direction: rtl;
}

.fr-box .CodeMirror {
  display: none;
}

.fr-box.fr-code-view textarea.fr-code {
  display: block;
}

.fr-box.fr-code-view.fr-inline {
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 1px 1px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 1px 1px rgba(0, 0, 0, 0.16);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 1px 1px rgba(0, 0, 0, 0.16);
}

.fr-box.fr-code-view .fr-element, .fr-box.fr-code-view .fr-placeholder, .fr-box.fr-code-view .fr-iframe {
  display: none;
}

.fr-box.fr-code-view .CodeMirror {
  display: block;
}

.fr-box.fr-inline.fr-code-view .fr-command.fr-btn.html-switch {
  display: block;
}

.fr-box.fr-inline .fr-command.fr-btn.html-switch {
  position: absolute;
  top: 0;
  right: 0;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 1px 1px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 1px 1px rgba(0, 0, 0, 0.16);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 1px 1px rgba(0, 0, 0, 0.16);
  display: none;
  background: #fff;
  color: #222;
  -moz-outline: 0;
  outline: 0;
  border: 0;
  line-height: 1;
  cursor: pointer;
  text-align: left;
  padding: 12px;
  -webkit-transition: background 0.2s ease 0s;
  -moz-transition: background 0.2s ease 0s;
  -ms-transition: background 0.2s ease 0s;
  -o-transition: background 0.2s ease 0s;
  border-radius: 0;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  z-index: 2;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  text-decoration: none;
  user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.fr-box.fr-inline .fr-command.fr-btn.html-switch i {
  font-size: 14px;
  width: 14px;
  text-align: center;
}

.fr-box.fr-inline .fr-command.fr-btn.html-switch.fr-desktop:hover {
  background: #ebebeb;
}

.datepicker--cell-day.-other-month-, .datepicker--cell-year.-other-decade- {
  color: #dedede;
}

.datepicker--cell-day.-other-month-:hover, .datepicker--cell-year.-other-decade-:hover {
  color: #c5c5c5;
}

.-disabled-.-focus-.datepicker--cell-day.-other-month-, .-disabled-.-focus-.datepicker--cell-year.-other-decade- {
  color: #dedede;
}

.-selected-.datepicker--cell-day.-other-month-, .-selected-.datepicker--cell-year.-other-decade- {
  color: #fff;
  background: #a2ddf6;
}

.-selected-.-focus-.datepicker--cell-day.-other-month-, .-selected-.-focus-.datepicker--cell-year.-other-decade- {
  background: #8ad5f4;
}

.-in-range-.datepicker--cell-day.-other-month-, .-in-range-.datepicker--cell-year.-other-decade- {
  background-color: rgba(92, 196, 239, 0.1);
  color: #cccccc;
}

.-in-range-.-focus-.datepicker--cell-day.-other-month-, .-in-range-.-focus-.datepicker--cell-year.-other-decade- {
  background-color: rgba(92, 196, 239, 0.2);
}

.datepicker--cell-day.-other-month-:empty, .datepicker--cell-year.-other-decade-:empty {
  background: none;
  border: none;
}

.datepicker--cell-day.-other-month-, .datepicker--cell-year.-other-decade- {
  color: #dedede;
}

.datepicker--cell-day.-other-month-:hover, .datepicker--cell-year.-other-decade-:hover {
  color: #c5c5c5;
}

.-disabled-.-focus-.datepicker--cell-day.-other-month-, .-disabled-.-focus-.datepicker--cell-year.-other-decade- {
  color: #dedede;
}

.-selected-.datepicker--cell-day.-other-month-, .-selected-.datepicker--cell-year.-other-decade- {
  color: #fff;
  background: #a2ddf6;
}

.-selected-.-focus-.datepicker--cell-day.-other-month-, .-selected-.-focus-.datepicker--cell-year.-other-decade- {
  background: #8ad5f4;
}

.-in-range-.datepicker--cell-day.-other-month-, .-in-range-.datepicker--cell-year.-other-decade- {
  background-color: rgba(92, 196, 239, 0.1);
  color: #cccccc;
}

.-in-range-.-focus-.datepicker--cell-day.-other-month-, .-in-range-.-focus-.datepicker--cell-year.-other-decade- {
  background-color: rgba(92, 196, 239, 0.2);
}

.datepicker--cell-day.-other-month-:empty, .datepicker--cell-year.-other-decade-:empty {
  background: none;
  border: none;
}

/* -------------------------------------------------
    Datepicker cells
   ------------------------------------------------- */
.datepicker--cells {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.datepicker--cell {
  border-radius: 4px;
  box-sizing: border-box;
  cursor: pointer;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 32px;
  z-index: 1;
}

.datepicker--cell.-focus- {
  background: #f0f0f0;
}

.datepicker--cell.-current- {
  color: #4EB5E6;
}

.datepicker--cell.-current-.-focus- {
  color: #4a4a4a;
}

.datepicker--cell.-current-.-in-range- {
  color: #4EB5E6;
}

.datepicker--cell.-in-range- {
  background: rgba(92, 196, 239, 0.1);
  color: #4a4a4a;
  border-radius: 0;
}

.datepicker--cell.-in-range-.-focus- {
  background-color: rgba(92, 196, 239, 0.2);
}

.datepicker--cell.-disabled- {
  cursor: default;
  color: #aeaeae;
}

.datepicker--cell.-disabled-.-focus- {
  color: #aeaeae;
}

.datepicker--cell.-disabled-.-in-range- {
  color: #a1a1a1;
}

.datepicker--cell.-disabled-.-current-.-focus- {
  color: #aeaeae;
}

.datepicker--cell.-range-from- {
  border: 1px solid rgba(92, 196, 239, 0.5);
  background-color: rgba(92, 196, 239, 0.1);
  border-radius: 4px 0 0 4px;
}

.datepicker--cell.-range-to- {
  border: 1px solid rgba(92, 196, 239, 0.5);
  background-color: rgba(92, 196, 239, 0.1);
  border-radius: 0 4px 4px 0;
}

.datepicker--cell.-range-from-.-range-to- {
  border-radius: 4px;
}

.datepicker--cell.-selected- {
  color: #fff;
  border: none;
  background: #5cc4ef;
}

.datepicker--cell.-selected-.-current- {
  color: #fff;
  background: #5cc4ef;
}

.datepicker--cell.-selected-.-focus- {
  background: #45bced;
}

.datepicker--cell:empty {
  cursor: default;
}

.datepicker--days-names {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 8px 0 3px;
}

.datepicker--day-name {
  color: #FF9A19;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex: 1;
  flex: 1;
  text-align: center;
  text-transform: uppercase;
  font-size: 0.8em;
}

.datepicker--cell-day {
  width: 14.28571%;
}

.datepicker--cells-months {
  height: 170px;
}

.datepicker--cell-month {
  width: 33.33%;
  height: 25%;
}

.datepicker--years {
  height: 170px;
}

.datepicker--cells-years {
  height: 170px;
}

.datepicker--cell-year {
  width: 25%;
  height: 33.33%;
}

.datepicker--cell-day.-other-month-, .datepicker--cell-year.-other-decade- {
  color: #dedede;
}

.datepicker--cell-day.-other-month-:hover, .datepicker--cell-year.-other-decade-:hover {
  color: #c5c5c5;
}

.-disabled-.-focus-.datepicker--cell-day.-other-month-, .-disabled-.-focus-.datepicker--cell-year.-other-decade- {
  color: #dedede;
}

.-selected-.datepicker--cell-day.-other-month-, .-selected-.datepicker--cell-year.-other-decade- {
  color: #fff;
  background: #a2ddf6;
}

.-selected-.-focus-.datepicker--cell-day.-other-month-, .-selected-.-focus-.datepicker--cell-year.-other-decade- {
  background: #8ad5f4;
}

.-in-range-.datepicker--cell-day.-other-month-, .-in-range-.datepicker--cell-year.-other-decade- {
  background-color: rgba(92, 196, 239, 0.1);
  color: #cccccc;
}

.-in-range-.-focus-.datepicker--cell-day.-other-month-, .-in-range-.-focus-.datepicker--cell-year.-other-decade- {
  background-color: rgba(92, 196, 239, 0.2);
}

.datepicker--cell-day.-other-month-:empty, .datepicker--cell-year.-other-decade-:empty {
  background: none;
  border: none;
}

/* -------------------------------------------------
    Datepicker
   ------------------------------------------------- */
.datepickers-container {
  position: absolute;
  left: 0;
  top: 0;
}

@media print {
  .datepickers-container {
    display: none;
  }
}
.datepicker {
  background: #fff;
  border: 1px solid #dbdbdb;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  box-sizing: content-box;
  font-family: Tahoma, sans-serif;
  font-size: 14px;
  color: #4a4a4a;
  width: 250px;
  position: absolute;
  left: -100000px;
  opacity: 0;
  transition: opacity 0.3s ease, transform 0.3s ease, left 0s 0.3s;
  z-index: 100;
}

html[dir=rtl] .datepicker {
  left: 0;
  /* Fix to prevent horizontal scrolling */
}

.datepicker.-from-top- {
  transform: translateY(-8px);
}

.datepicker.-from-right- {
  transform: translateX(8px);
}

.datepicker.-from-bottom- {
  transform: translateY(8px);
}

.datepicker.-from-left- {
  transform: translateX(-8px);
}

.datepicker.active {
  opacity: 1;
  transform: translate(0);
  transition: opacity 0.3s ease, transform 0.3s ease, left 0s 0s;
}

.datepicker-inline .datepicker {
  border-color: #d7d7d7;
  box-shadow: none;
  position: static;
  left: auto;
  right: auto;
  opacity: 1;
  transform: none;
}

.datepicker-inline .datepicker--pointer {
  display: none;
}

.datepicker--content {
  box-sizing: content-box;
  padding: 4px;
}

.-only-timepicker- .datepicker--content {
  display: none;
}

.datepicker--pointer {
  position: absolute;
  background: #fff;
  border-top: 1px solid #dbdbdb;
  border-right: 1px solid #dbdbdb;
  width: 10px;
  height: 10px;
  z-index: -1;
}

.-top-left- .datepicker--pointer, .-top-center- .datepicker--pointer, .-top-right- .datepicker--pointer {
  top: calc(100% - 4px);
  transform: rotate(135deg);
}

.-right-top- .datepicker--pointer, .-right-center- .datepicker--pointer, .-right-bottom- .datepicker--pointer {
  right: calc(100% - 4px);
  transform: rotate(225deg);
}

.-bottom-left- .datepicker--pointer, .-bottom-center- .datepicker--pointer, .-bottom-right- .datepicker--pointer {
  bottom: calc(100% - 4px);
  transform: rotate(315deg);
}

.-left-top- .datepicker--pointer, .-left-center- .datepicker--pointer, .-left-bottom- .datepicker--pointer {
  left: calc(100% - 4px);
  transform: rotate(45deg);
}

.-top-left- .datepicker--pointer, .-bottom-left- .datepicker--pointer {
  left: 10px;
}

.-top-right- .datepicker--pointer, .-bottom-right- .datepicker--pointer {
  right: 10px;
}

.-top-center- .datepicker--pointer, .-bottom-center- .datepicker--pointer {
  left: calc(50% - 5px);
}

.-left-top- .datepicker--pointer, .-right-top- .datepicker--pointer {
  top: 10px;
}

.-left-bottom- .datepicker--pointer, .-right-bottom- .datepicker--pointer {
  bottom: 10px;
}

.-left-center- .datepicker--pointer, .-right-center- .datepicker--pointer {
  top: calc(50% - 5px);
}

.datepicker--body {
  display: none;
}

.datepicker--body.active {
  display: block;
}

.datepicker--cell-day.-other-month-, .datepicker--cell-year.-other-decade- {
  color: #dedede;
}

.datepicker--cell-day.-other-month-:hover, .datepicker--cell-year.-other-decade-:hover {
  color: #c5c5c5;
}

.-disabled-.-focus-.datepicker--cell-day.-other-month-, .-disabled-.-focus-.datepicker--cell-year.-other-decade- {
  color: #dedede;
}

.-selected-.datepicker--cell-day.-other-month-, .-selected-.datepicker--cell-year.-other-decade- {
  color: #fff;
  background: #a2ddf6;
}

.-selected-.-focus-.datepicker--cell-day.-other-month-, .-selected-.-focus-.datepicker--cell-year.-other-decade- {
  background: #8ad5f4;
}

.-in-range-.datepicker--cell-day.-other-month-, .-in-range-.datepicker--cell-year.-other-decade- {
  background-color: rgba(92, 196, 239, 0.1);
  color: #cccccc;
}

.-in-range-.-focus-.datepicker--cell-day.-other-month-, .-in-range-.-focus-.datepicker--cell-year.-other-decade- {
  background-color: rgba(92, 196, 239, 0.2);
}

.datepicker--cell-day.-other-month-:empty, .datepicker--cell-year.-other-decade-:empty {
  background: none;
  border: none;
}

/* -------------------------------------------------
    Navigation
   ------------------------------------------------- */
.datepicker--nav {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-bottom: 1px solid #efefef;
  min-height: 32px;
  padding: 4px;
}

.-only-timepicker- .datepicker--nav {
  display: none;
}

.datepicker--nav-title,
.datepicker--nav-action {
  display: -ms-flexbox;
  display: flex;
  cursor: pointer;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.datepicker--nav-action {
  width: 32px;
  border-radius: 4px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.datepicker--nav-action:hover {
  background: #f0f0f0;
}

.datepicker--nav-action.-disabled- {
  visibility: hidden;
}

.datepicker--nav-action svg {
  width: 32px;
  height: 32px;
}

.datepicker--nav-action path {
  fill: none;
  stroke: #9c9c9c;
  stroke-width: 2px;
}

.datepicker--nav-title {
  border-radius: 4px;
  padding: 0 8px;
}

.datepicker--nav-title i {
  font-style: normal;
  color: #9c9c9c;
  margin-left: 5px;
}

.datepicker--nav-title:hover {
  background: #f0f0f0;
}

.datepicker--nav-title.-disabled- {
  cursor: default;
  background: none;
}

.datepicker--buttons {
  display: -ms-flexbox;
  display: flex;
  padding: 4px;
  border-top: 1px solid #efefef;
}

.datepicker--button {
  color: #4EB5E6;
  cursor: pointer;
  border-radius: 4px;
  -ms-flex: 1;
  flex: 1;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  height: 32px;
}

.datepicker--button:hover {
  color: #4a4a4a;
  background: #f0f0f0;
}

.datepicker--cell-day.-other-month-, .datepicker--cell-year.-other-decade- {
  color: #dedede;
}

.datepicker--cell-day.-other-month-:hover, .datepicker--cell-year.-other-decade-:hover {
  color: #c5c5c5;
}

.-disabled-.-focus-.datepicker--cell-day.-other-month-, .-disabled-.-focus-.datepicker--cell-year.-other-decade- {
  color: #dedede;
}

.-selected-.datepicker--cell-day.-other-month-, .-selected-.datepicker--cell-year.-other-decade- {
  color: #fff;
  background: #a2ddf6;
}

.-selected-.-focus-.datepicker--cell-day.-other-month-, .-selected-.-focus-.datepicker--cell-year.-other-decade- {
  background: #8ad5f4;
}

.-in-range-.datepicker--cell-day.-other-month-, .-in-range-.datepicker--cell-year.-other-decade- {
  background-color: rgba(92, 196, 239, 0.1);
  color: #cccccc;
}

.-in-range-.-focus-.datepicker--cell-day.-other-month-, .-in-range-.-focus-.datepicker--cell-year.-other-decade- {
  background-color: rgba(92, 196, 239, 0.2);
}

.datepicker--cell-day.-other-month-:empty, .datepicker--cell-year.-other-decade-:empty {
  background: none;
  border: none;
}

/* -------------------------------------------------
    Timepicker
   ------------------------------------------------- */
.datepicker--time {
  border-top: 1px solid #efefef;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding: 4px;
  position: relative;
}

.datepicker--time.-am-pm- .datepicker--time-sliders {
  -ms-flex: 0 1 138px;
  flex: 0 1 138px;
  max-width: 138px;
}

.-only-timepicker- .datepicker--time {
  border-top: none;
}

.datepicker--time-sliders {
  -ms-flex: 0 1 153px;
  flex: 0 1 153px;
  margin-right: 10px;
  max-width: 153px;
}

.datepicker--time-label {
  display: none;
  font-size: 12px;
}

.datepicker--time-current {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex: 1;
  flex: 1;
  font-size: 14px;
  text-align: center;
  margin: 0 0 0 10px;
}

html[dir=rtl] .datepicker--time-current {
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}

.datepicker--time-current-colon {
  margin: 0 2px 3px;
  line-height: 1;
}

.datepicker--time-current-hours,
.datepicker--time-current-minutes {
  line-height: 1;
  font-size: 19px;
  font-family: "Century Gothic", CenturyGothic, AppleGothic, sans-serif;
  position: relative;
  z-index: 1;
}

.datepicker--time-current-hours:after,
.datepicker--time-current-minutes:after {
  content: "";
  background: #f0f0f0;
  border-radius: 4px;
  position: absolute;
  left: -2px;
  top: -3px;
  right: -2px;
  bottom: -2px;
  z-index: -1;
  opacity: 0;
}

.datepicker--time-current-hours.-focus-:after,
.datepicker--time-current-minutes.-focus-:after {
  opacity: 1;
}

.datepicker--time-current-ampm {
  text-transform: uppercase;
  -ms-flex-item-align: end;
  align-self: flex-end;
  color: #9c9c9c;
  margin-left: 6px;
  font-size: 11px;
  margin-bottom: 1px;
}

html[dir=rtl] .datepicker--time-current-ampm {
  -ms-flex-order: -1;
  order: -1;
  margin-left: 0;
  margin-right: 6px;
}

.datepicker--time-row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  font-size: 11px;
  height: 17px;
  background: linear-gradient(to right, #dedede, #dedede) left 50%/100% 1px no-repeat;
}

.datepicker--time-row:first-child {
  margin-bottom: 4px;
}

.datepicker--time-row input[type=range] {
  background: none;
  cursor: pointer;
  -ms-flex: 1;
  flex: 1;
  height: 100%;
  padding: 0;
  margin: 0;
  -webkit-appearance: none;
}

.datepicker--time-row input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
}

.datepicker--time-row input[type=range]::-ms-tooltip {
  display: none;
}

.datepicker--time-row input[type=range]:hover::-webkit-slider-thumb {
  border-color: #b8b8b8;
}

.datepicker--time-row input[type=range]:hover::-moz-range-thumb {
  border-color: #b8b8b8;
}

.datepicker--time-row input[type=range]:hover::-ms-thumb {
  border-color: #b8b8b8;
}

.datepicker--time-row input[type=range]:focus {
  outline: none;
}

.datepicker--time-row input[type=range]:focus::-webkit-slider-thumb {
  background: #5cc4ef;
  border-color: #5cc4ef;
}

.datepicker--time-row input[type=range]:focus::-moz-range-thumb {
  background: #5cc4ef;
  border-color: #5cc4ef;
}

.datepicker--time-row input[type=range]:focus::-ms-thumb {
  background: #5cc4ef;
  border-color: #5cc4ef;
}

.datepicker--time-row input[type=range]::-webkit-slider-thumb {
  box-sizing: border-box;
  height: 12px;
  width: 12px;
  border-radius: 3px;
  border: 1px solid #dedede;
  background: #fff;
  cursor: pointer;
  transition: background 0.2s;
}

.datepicker--time-row input[type=range]::-moz-range-thumb {
  box-sizing: border-box;
  height: 12px;
  width: 12px;
  border-radius: 3px;
  border: 1px solid #dedede;
  background: #fff;
  cursor: pointer;
  transition: background 0.2s;
}

.datepicker--time-row input[type=range]::-ms-thumb {
  box-sizing: border-box;
  height: 12px;
  width: 12px;
  border-radius: 3px;
  border: 1px solid #dedede;
  background: #fff;
  cursor: pointer;
  transition: background 0.2s;
}

.datepicker--time-row input[type=range]::-webkit-slider-thumb {
  margin-top: -6px;
}

.datepicker--time-row input[type=range]::-webkit-slider-runnable-track {
  border: none;
  height: 1px;
  cursor: pointer;
  color: transparent;
  background: transparent;
}

.datepicker--time-row input[type=range]::-moz-range-track {
  border: none;
  height: 1px;
  cursor: pointer;
  color: transparent;
  background: transparent;
}

.datepicker--time-row input[type=range]::-ms-track {
  border: none;
  height: 1px;
  cursor: pointer;
  color: transparent;
  background: transparent;
}

.datepicker--time-row input[type=range]::-ms-fill-lower {
  background: transparent;
}

.datepicker--time-row input[type=range]::-ms-fill-upper {
  background: transparent;
}

.datepicker--time-row span {
  padding: 0 12px;
}

.datepicker--time-icon {
  color: #9c9c9c;
  border: 1px solid;
  border-radius: 50%;
  font-size: 16px;
  position: relative;
  margin: 0 5px -1px 0;
  width: 1em;
  height: 1em;
}

.datepicker--time-icon:after, .datepicker--time-icon:before {
  content: "";
  background: currentColor;
  position: absolute;
}

.datepicker--time-icon:after {
  height: 0.4em;
  width: 1px;
  left: calc(50% - 1px);
  top: calc(50% + 1px);
  transform: translateY(-100%);
}

.datepicker--time-icon:before {
  width: 0.4em;
  height: 1px;
  top: calc(50% + 1px);
  left: calc(50% - 1px);
}

/*! angularjs-slider - v7.0.0 - 
 (c) Rafal Zajac <rzajac@gmail.com>, Valentin Hervieu <valentin@hervi.eu>, Jussi Saarivirta <jusasi@gmail.com>, Angelin Sirbu <angelin.sirbu@gmail.com> - 
 https://github.com/angular-slider/angularjs-slider - 
 2019-02-23 */
.rzslider {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 4px;
  margin: 35px 0 15px 0;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.rzslider.noanimate * {
  transition: none !important;
}

.rzslider.with-legend {
  margin-bottom: 40px;
}

.rzslider[disabled] {
  cursor: not-allowed;
}

.rzslider[disabled] .rz-pointer {
  cursor: not-allowed;
  background-color: #d8e0f3;
}

.rzslider[disabled] .rz-draggable {
  cursor: not-allowed;
}

.rzslider[disabled] .rz-selection {
  background: #8b91a2;
}

.rzslider[disabled] .rz-tick {
  cursor: not-allowed;
}

.rzslider[disabled] .rz-tick.rz-selected {
  background: #8b91a2;
}

.rzslider span {
  position: absolute;
  display: inline-block;
  white-space: nowrap;
}

.rzslider .rz-base {
  width: 100%;
  height: 100%;
  padding: 0;
}

.rzslider .rz-bar-wrapper {
  left: 0;
  z-index: 1;
  width: 100%;
  height: 32px;
  padding-top: 16px;
  margin-top: -16px;
  box-sizing: border-box;
  transition: all linear 0.3s;
}

.rzslider .rz-draggable {
  cursor: move;
}

.rzslider .rz-bar {
  left: 0;
  z-index: 1;
  width: 100%;
  height: 4px;
  background: #d8e0f3;
  border-radius: 2px;
}

.rzslider .rz-bar-wrapper.rz-transparent .rz-bar {
  background: transparent;
}

.rzslider .rz-bar-wrapper.rz-left-out-selection .rz-bar {
  background: #df002d;
}

.rzslider .rz-bar-wrapper.rz-right-out-selection .rz-bar {
  background: #03a688;
}

.rzslider .rz-selection {
  z-index: 2;
  background: #0db9f0;
  border-radius: 2px;
  transition: background-color linear 0.3s;
}

.rzslider .rz-restricted {
  z-index: 3;
  background: #ff0000;
  border-radius: 2px;
}

.rzslider .rz-pointer {
  top: -14px;
  z-index: 3;
  width: 32px;
  height: 32px;
  cursor: pointer;
  background-color: #0db9f0;
  border-radius: 16px;
  transition: all linear 0.3s;
}

.rzslider .rz-pointer:after {
  position: absolute;
  top: 12px;
  left: 12px;
  width: 8px;
  height: 8px;
  background: #ffffff;
  border-radius: 4px;
  content: "";
}

.rzslider .rz-pointer:hover:after {
  background-color: #ffffff;
}

.rzslider .rz-pointer.rz-active {
  z-index: 4;
}

.rzslider .rz-pointer.rz-active:after {
  background-color: #451aff;
}

.rzslider .rz-bubble {
  bottom: 16px;
  padding: 1px 3px;
  color: #55637d;
  cursor: default;
  transition: all linear 0.3s;
}

.rzslider .rz-bubble.rz-limit {
  color: #55637d;
  transition: none;
}

.rzslider .rz-ticks {
  position: absolute;
  top: -3px;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 0;
  margin: 0;
  list-style: none;
  box-sizing: border-box;
}

.rzslider .rz-ticks-values-under .rz-tick-value {
  top: auto;
  bottom: -32px;
}

.rzslider .rz-tick {
  position: absolute;
  top: 0;
  left: 0;
  width: 10px;
  height: 10px;
  margin-left: 11px;
  text-align: center;
  cursor: pointer;
  background: #d8e0f3;
  border-radius: 50%;
  transition: background-color linear 0.3s;
}

.rzslider .rz-tick.rz-selected {
  background: #0db9f0;
}

.rzslider .rz-tick-value {
  position: absolute;
  top: -30px;
  transform: translate(-50%, 0);
}

.rzslider .rz-tick-legend {
  position: absolute;
  top: 24px;
  max-width: 50px;
  white-space: normal;
  transform: translate(-50%, 0);
}

.rzslider.rz-vertical {
  position: relative;
  width: 4px;
  height: 100%;
  padding: 0;
  margin: 0 20px;
  vertical-align: baseline;
}

.rzslider.rz-vertical .rz-base {
  width: 100%;
  height: 100%;
  padding: 0;
}

.rzslider.rz-vertical .rz-bar-wrapper {
  top: auto;
  left: 0;
  width: 32px;
  height: 100%;
  padding: 0 0 0 16px;
  margin: 0 0 0 -16px;
}

.rzslider.rz-vertical .rz-bar {
  bottom: 0;
  left: auto;
  width: 4px;
  height: 100%;
}

.rzslider.rz-vertical .rz-pointer {
  top: auto;
  bottom: 0;
  left: -14px !important;
}

.rzslider.rz-vertical .rz-bubble {
  bottom: 0;
  left: 16px !important;
  margin-left: 3px;
}

.rzslider.rz-vertical .rz-ticks {
  top: 0;
  left: -3px;
  z-index: 1;
  width: 0;
  height: 100%;
}

.rzslider.rz-vertical .rz-tick {
  margin-top: 11px;
  margin-left: auto;
  vertical-align: middle;
}

.rzslider.rz-vertical .rz-tick-value {
  top: auto;
  left: 24px;
  transform: translate(0, -28%);
}

.rzslider.rz-vertical .rz-tick-legend {
  top: auto;
  right: 24px;
  max-width: none;
  white-space: nowrap;
  transform: translate(0, -28%);
}

.rzslider.rz-vertical .rz-ticks-values-under .rz-tick-value {
  right: 24px;
  bottom: auto;
  left: auto;
}


/* BASICS */
.CodeMirror {
  /* Set height, width, borders, and global font properties here */
  font-family: monospace;
  height: 300px;
  color: black;
}

/* PADDING */
.CodeMirror-lines {
  padding: 4px 0; /* Vertical padding around content */
}

.CodeMirror pre {
  padding: 0 4px; /* Horizontal padding of content */
}

.CodeMirror-scrollbar-filler, .CodeMirror-gutter-filler {
  background-color: white; /* The little square between H and V scrollbars */
}

/* GUTTER */
.CodeMirror-gutters {
  border-right: 1px solid #ddd;
  background-color: #f7f7f7;
  white-space: nowrap;
}

.CodeMirror-linenumber {
  padding: 0 3px 0 5px;
  min-width: 20px;
  text-align: right;
  color: #999;
  white-space: nowrap;
}

.CodeMirror-guttermarker {
  color: black;
}

.CodeMirror-guttermarker-subtle {
  color: #999;
}

/* CURSOR */
.CodeMirror div.CodeMirror-cursor {
  border-left: 1px solid black;
}

/* Shown when moving in bi-directional text */
.CodeMirror div.CodeMirror-secondarycursor {
  border-left: 1px solid silver;
}

.CodeMirror.cm-fat-cursor div.CodeMirror-cursor {
  width: auto;
  border: 0;
  background: #7e7;
}

.CodeMirror.cm-fat-cursor div.CodeMirror-cursors {
  z-index: 1;
}

.cm-animate-fat-cursor {
  width: auto;
  border: 0;
  -webkit-animation: blink 1.06s steps(1) infinite;
  -moz-animation: blink 1.06s steps(1) infinite;
  animation: blink 1.06s steps(1) infinite;
}

@-moz-keyframes blink {
  0% {
    background: #7e7;
  }
  50% {
    background: none;
  }
  100% {
    background: #7e7;
  }
}
@-webkit-keyframes blink {
  0% {
    background: #7e7;
  }
  50% {
    background: none;
  }
  100% {
    background: #7e7;
  }
}
@keyframes blink {
  0% {
    background: #7e7;
  }
  50% {
    background: none;
  }
  100% {
    background: #7e7;
  }
}
/* Can style cursor different in overwrite (non-insert) mode */
.cm-tab {
  display: inline-block;
  text-decoration: inherit;
}

.CodeMirror-ruler {
  border-left: 1px solid #ccc;
  position: absolute;
}

/* DEFAULT THEME */
.cm-s-default .cm-keyword {
  color: #708;
}

.cm-s-default .cm-atom {
  color: #219;
}

.cm-s-default .cm-number {
  color: #164;
}

.cm-s-default .cm-def {
  color: #00f;
}

.cm-s-default .cm-variable-2 {
  color: #05a;
}

.cm-s-default .cm-variable-3 {
  color: #085;
}

.cm-s-default .cm-comment {
  color: #a50;
}

.cm-s-default .cm-string {
  color: #a11;
}

.cm-s-default .cm-string-2 {
  color: #f50;
}

.cm-s-default .cm-meta {
  color: #555;
}

.cm-s-default .cm-qualifier {
  color: #555;
}

.cm-s-default .cm-builtin {
  color: #30a;
}

.cm-s-default .cm-bracket {
  color: #997;
}

.cm-s-default .cm-tag {
  color: #170;
}

.cm-s-default .cm-attribute {
  color: #00c;
}

.cm-s-default .cm-header {
  color: blue;
}

.cm-s-default .cm-quote {
  color: #090;
}

.cm-s-default .cm-hr {
  color: #999;
}

.cm-s-default .cm-link {
  color: #00c;
}

.cm-negative {
  color: #d44;
}

.cm-positive {
  color: #292;
}

.cm-header, .cm-strong {
  font-weight: bold;
}

.cm-em {
  font-style: italic;
}

.cm-link {
  text-decoration: underline;
}

.cm-strikethrough {
  text-decoration: line-through;
}

.cm-s-default .cm-error {
  color: #f00;
}

.cm-invalidchar {
  color: #f00;
}

.CodeMirror-composing {
  border-bottom: 2px solid;
}

/* Default styles for common addons */
div.CodeMirror span.CodeMirror-matchingbracket {
  color: #0f0;
}

div.CodeMirror span.CodeMirror-nonmatchingbracket {
  color: #f22;
}

.CodeMirror-matchingtag {
  background: rgba(255, 150, 0, 0.3);
}

.CodeMirror-activeline-background {
  background: #e8f2ff;
}

/* STOP */
/* The rest of this file contains styles related to the mechanics of
   the editor. You probably shouldn't touch them. */
.CodeMirror {
  position: relative;
  overflow: hidden;
  background: white;
}

.CodeMirror-scroll {
  overflow: scroll !important; /* Things will break if this is overridden */
  /* 30px is the magic margin used to hide the element's real scrollbars */
  /* See overflow: hidden in .CodeMirror */
  margin-bottom: -30px;
  margin-right: -30px;
  padding-bottom: 30px;
  height: 100%;
  outline: none; /* Prevent dragging from highlighting the element */
  position: relative;
}

.CodeMirror-sizer {
  position: relative;
  border-right: 30px solid transparent;
}

/* The fake, visible scrollbars. Used to force redraw during scrolling
   before actuall scrolling happens, thus preventing shaking and
   flickering artifacts. */
.CodeMirror-vscrollbar, .CodeMirror-hscrollbar, .CodeMirror-scrollbar-filler, .CodeMirror-gutter-filler {
  position: absolute;
  z-index: 6;
  display: none;
}

.CodeMirror-vscrollbar {
  right: 0;
  top: 0;
  overflow-x: hidden;
  overflow-y: scroll;
}

.CodeMirror-hscrollbar {
  bottom: 0;
  left: 0;
  overflow-y: hidden;
  overflow-x: scroll;
}

.CodeMirror-scrollbar-filler {
  right: 0;
  bottom: 0;
}

.CodeMirror-gutter-filler {
  left: 0;
  bottom: 0;
}

.CodeMirror-gutters {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 3;
}

.CodeMirror-gutter {
  white-space: normal;
  height: 100%;
  display: inline-block;
  margin-bottom: -30px;
  /* Hack to make IE7 behave */
  *zoom: 1;
  *display: inline;
}

.CodeMirror-gutter-wrapper {
  position: absolute;
  z-index: 4;
  height: 100%;
}

.CodeMirror-gutter-elt {
  position: absolute;
  cursor: default;
  z-index: 4;
}

.CodeMirror-gutter-wrapper {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.CodeMirror-lines {
  cursor: text;
  min-height: 1px; /* prevents collapsing before first draw */
}

.CodeMirror pre {
  /* Reset some styles that the rest of the page might have set */
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
  border-width: 0;
  background: transparent;
  font-family: inherit;
  font-size: inherit;
  margin: 0;
  white-space: pre;
  word-wrap: normal;
  line-height: inherit;
  color: inherit;
  z-index: 2;
  position: relative;
  overflow: visible;
  -webkit-tap-highlight-color: transparent;
}

.CodeMirror-wrap pre {
  word-wrap: break-word;
  white-space: pre-wrap;
  word-break: normal;
}

.CodeMirror-linebackground {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 0;
}

.CodeMirror-linewidget {
  position: relative;
  z-index: 2;
  overflow: auto;
}

.CodeMirror-code {
  outline: none;
}

/* Force content-box sizing for the elements where we expect it */
.CodeMirror-scroll,
.CodeMirror-sizer,
.CodeMirror-gutter,
.CodeMirror-gutters,
.CodeMirror-linenumber {
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

.CodeMirror-measure {
  position: absolute;
  width: 100%;
  height: 0;
  overflow: hidden;
  visibility: hidden;
}

.CodeMirror-measure pre {
  position: static;
}

.CodeMirror div.CodeMirror-cursor {
  position: absolute;
  border-right: none;
  width: 0;
}

div.CodeMirror-cursors {
  visibility: hidden;
  position: relative;
  z-index: 3;
}

.CodeMirror-focused div.CodeMirror-cursors {
  visibility: visible;
}

.CodeMirror-selected {
  background: #d9d9d9;
}

.CodeMirror-focused .CodeMirror-selected {
  background: #d7d4f0;
}

.CodeMirror-crosshair {
  cursor: crosshair;
}

.CodeMirror ::selection {
  background: #d7d4f0;
}

.CodeMirror ::-moz-selection {
  background: #d7d4f0;
}

.cm-searching {
  background: #ffa;
  background: rgba(255, 255, 0, 0.4);
}

/* IE7 hack to prevent it from returning funny offsetTops on the spans */
.CodeMirror span {
  *vertical-align: text-bottom;
}

/* Used to force a border model for a node */
.cm-force-border {
  padding-right: 0.1px;
}

@media print {
  /* Hide the cursor when printing */
  .CodeMirror div.CodeMirror-cursors {
    visibility: hidden;
  }
}
/* See issue #2901 */
.cm-tab-wrap-hack:after {
  content: "";
}

/* Help users use markselection to safely style text background */
span.CodeMirror-selectedtext {
  background: none;
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
}
.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}
[dir=rtl] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

.datepicker {
  z-index: 1000;
}
html[dir=rtl] .datepicker {
  left: 0;
  right: -100000px;
}
html[dir=rtl] .datepicker.active {
  right: unset;
}

/*rtl:end:ignore*/